import React from "react";
import { Link } from "react-router-dom";

export default ({ handleLogout, handleProfile, loginUser, darkModeState, language }) => {
  const styles = {
    sidebar: {
      width: '250px',
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      zIndex: 800,
      backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
      //  boxShadow: '0 3px 16px 0 rgba(50, 50, 255, 0.2)',
      //  boxShadow: '0 3px 16px 0 hsl(228, 28%, 1%)',
      boxShadow: `${darkModeState ? ("0 3px 16px 0 hsl(228, 28%, 11%)") : ("0 3px 16px 0 rgba(90, 90, 90, 0.2)")}`,
      opacity: 1,
      color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
      // fontSize: 15
      //  transition: all 0.3s;
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "36px 30px 20px",
      backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
    },
    linkContainer: {
      display: "flex",
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '80%'
    },
    link: {
      padding: 20,
      paddingLeft: 50,
      display: "flex",
      alignItems: "center",
    },
    linkButton: {
      padding: 20,
      paddingLeft: 50,
      display: "flex",
      alignItems: "center",
      backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
      color: '#63687e',
      border: 'none',
      outline: 0,
      width: '100%'
    },
    icons: {
      marginRight: "15px",
      width: '18px',
      height: '20px',
      color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
    },
    option: {
      color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
      fontSize: '16.5px',
      margin: '0px',
      textAlign: 'left'
    }
  };

  return (
    <div className="wrapper">
      <nav
        //id="sidebar" 
        style={styles.sidebar}>
        <div style={styles.logoContainer}>
          <img
            src="https://i.postimg.cc/bN6SJ6fx/logo-insideone.png"
            height="55"
            alt="logo of Inside One"
          />
        </div>
        <hr />
        <div style={styles.linkContainer}>
          <ul className="list-unstyled" >
            {loginUser.oneAgent === 'true' ? (<>
              <li>
                <Link className="nav-link active" style={styles.link} to="/today">
                  <svg style={styles.icons} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                  {language === 'english' ? ('Today') : ('Hoy')}
                </Link>
              </li>
              {loginUser.rol === 'Developer' || loginUser.rol === 'Supervisor' ? (
                <li>
                  <Link className="nav-link active" style={styles.link} to="/analytics">
                    <svg style={styles.icons} viewBox="0 0 16 16" className="bi bi-bar-chart" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5h-2v12h2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z" />
                    </svg>
                    {language === 'english' ? ('Analytics') : ('Métricas')}
                  </Link>
                </li>
              ) : (<></>)}

              {loginUser.rol === 'Developer' || loginUser.rol === 'Supervisor' ? (
                <li>
                  <Link className="nav-link active" style={styles.link} to="/agents">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                    </svg>
                    {language === 'english' ? ('Agents') : ('Agentes')}
                  </Link>
                </li>
              ) : (<></>)}
              {loginUser.rol === 'Developer' || loginUser.rol === 'Supervisor' ? (
                <li>
                  <Link className="nav-link active" style={styles.link} to="/campaigns">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
                      <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                    </svg>
                    {language === 'english' ? ('Campaigns') : ('Campañas')}
                  </Link>
                </li>
              ) : (<></>)}
            </>) : (<></>)}







            {loginUser.oneMessage === 'true' ? (<>


              {loginUser.rol === 'Developer' || loginUser.rol === 'Supervisor' ? (

                <>



                  {loginUser.company != 'qdSTVBdbXQdvIWGbfSgI' ? (
                    <li>
                      <Link className="nav-link active" style={styles.link} to="/messages">
                        <svg style={styles.icons} fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                        {language === 'english' ? ('Messages') : ('Mensajes')}
                      </Link>
                    </li>

                  ) : (


                    <li>
                      <Link className="nav-link active" style={styles.link} to="/onemessage">
                        <svg style={styles.icons} fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                        {language === 'english' ? ('Messages') : ('Mensajes')}
                      </Link>
                    </li>
                  )}





                </>







              ) : (<></>)}



            </>) : (<></>)}






            {loginUser.rol === 'Developer' || loginUser.rol === 'Agent' || loginUser.rol === 'Supervisor' ? (
              <li>
                <Link className="nav-link active" style={styles.link} to="/settings">
                  <svg style={styles.icons} fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                  </svg>
                  {language === 'english' ? ('Settings') : ('Ajustes')}
                </Link>
              </li>
            ) : (<></>)}
            {loginUser.oneAgent === 'true' ? (<>
              <li>
                <Link className="nav-link active" style={styles.link}
                  onClick={() => (window.open("https://insideone.zendesk.com/hc/es/requests/new"))}
                >
                  <svg style={styles.icons} fill="currentColor" class="bi bi-life-preserver" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  {language === 'english' ? ('Support') : ('Soporte')}
                </Link>
              </li>
            </>) : (<></>)}
          </ul>
          <ul className="list-unstyled" >
            {loginUser ? (
              <li>
                <button className="nav-link active" style={styles.linkButton} onClick={handleProfile}>
                  <svg style={styles.icons} fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg>
                  <h1 style={styles.option}>{language === 'english' ? (`Hello ${loginUser.firstName}!`) : (`Hola ${loginUser.firstName}!`)}</h1>
                </button>
              </li>
            ) : (<></>)}
            <li>
              <button className="nav-link active" style={styles.linkButton} onClick={handleLogout}>
                <svg style={styles.icons} fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <h1 style={styles.option}>{language === 'english' ? ('Log Out') : ('Salir')}</h1>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div >
  );
};
