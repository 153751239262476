import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import Campaigns from "../components/Campaigns";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const CampaignsClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [campaigns, setCampaigns] = useState([]);
    const [campaignsActive, setCampaignsActive] = useState(0);
    const [campaignsInactive, setCampaignsInactive] = useState(0);

    const dispatch = useDispatch()

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    useEffect(() => {
        window.scrollTo(0, 0);
        // window.addEventListener("resize", handleResize);
        if (loginUser.company) {
            let companyId = loginUser.company
            db.collection('companies').doc(companyId).collection('campaigns').orderBy('name')
                .onSnapshot((campaignsDocs) => {
                    let campaignsToState = []
                    let campaignsActiveFn = 0
                    let campaignsInactiveFn = 0
                    campaignsDocs.forEach((singleCampaign) => {
                        campaignsToState.push({
                            id: singleCampaign.id,
                            name: singleCampaign.data().name,
                            type: singleCampaign.data().type,
                            status: singleCampaign.data().status,
                        })
                        if (singleCampaign.data().status == 'Active') {
                            campaignsActiveFn = campaignsActiveFn + 1
                        } else {
                            campaignsInactiveFn = campaignsInactiveFn + 1
                        }
                    })
                    setCampaigns(campaignsToState)
                    setCampaignsActive(campaignsActiveFn)
                    setCampaignsInactive(campaignsInactiveFn)
                })
        }
    }, []);

    return (
        <div>
            <Sidebar />
            <Campaigns
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                campaigns={campaigns}
                campaignsActive={campaignsActive}
                campaignsInactive={campaignsInactive}
            />
        </div>
    );
}

export default CampaignsClient;