const initialState = {
  loginUser: {},
  darkTheme: false,
  language: 'spanish',
  status: 'pending',
  isAuth: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      //   console.log('como llego>', action.user)
      return Object.assign({}, state, {
        loginUser: action.user,
        isAuth: true,
      });
    case "SET_LOGOUT":
      return Object.assign({}, state, {
        loginUser: action.user,
        isAuth: false,
      });
    case "SET_DARK_THEME":
      return Object.assign({}, state, {
        darkTheme: action.darkTheme,
      });
    case "SET_LANGUAGE":
      return Object.assign({}, state, {
        language: action.language,
      });
    case "SET_READY":
      return Object.assign({}, state, {
        status: action.status,
      });
    default:
      return state;
  }
};
