import React, { useState, useEffect, useRef } from "react";
import Today from "../components/Today";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'
import axios from "axios";
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import { saveMessagesSelected, saveChatSelected, savePersonSelected, saveChannel, saveMessages, saveMessagesMe } from "../store/actions/messageAction";
import { useStopwatch } from 'react-timer-hook';

const db = firebase.firestore()

const TodayClient = () => {

    const dispatch = useDispatch()

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)
    const status = useSelector(state => state.user.status)
    const chatsRedux = useSelector(state => state.message.messages)
    const chatsMeRedux = useSelector(state => state.message.messagesMe)
    const messagesSelectedRedux = useSelector(state => state.message.messageSelected)
    const chatSelectedRedux = useSelector(state => state.message.chatId)
    const personSelectedState = useSelector(state => state.message.userId)
    const channelRedux = useSelector(state => state.message.channel)

    const messageEl = useRef(null);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [someAssigned, setSomeAssigned] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [popUpFeatures, setPopUpFeatures] = useState(false);
    const [popUpClose, setPopUpClose] = useState(false);
    const [popUpAssign, setPopUpAssign] = useState(false);
    const [chats, setChats] = useState([]);
    const [chatsMe, setChatsMe] = useState([]);
    const [chatSelected, setChatSelected] = useState('');
    const [messageTextbox, setMessageTextbox] = useState('');
    const [active, setActive] = useState(true);
    const [unsuscribe, setUnsuscribe] = useState('');

    const { seconds, minutes, hours, days, isRunning, start, pause, stop, reset } = useStopwatch({ autoStart: false });

    useEffect(() => {
        if (chatSelectedRedux != '' && messagesSelectedRedux.nameUser) {
            if (status == 'ready') {
                if (messageEl) {
                    messageEl.current.addEventListener('DOMNodeInserted', event => {
                        const { currentTarget: target } = event;
                        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
                    });
                }
            }
        }
    }, [chatSelectedRedux])

    const handleDarkMode = () => {
        if (darkTheme === false) {
            dispatch(loginUserPreferences('darkTheme', true))

        } else {
            dispatch(loginUserPreferences('darkTheme', false))
        }
    };

    const handleResize = (e) => { setScreenSize(window.innerWidth) };

    const handleReady = (e) => {
        if (e === 'ready' && status !== 'ready') {
            dispatch(loginUserPreferences('status', 'ready'))
            reset()
        } else if (e === 'break' && status !== 'break') {
            dispatch(loginUserPreferences('status', 'break'))
            start()
        } else if (e === 'lunch' && status !== 'lunch') {
            dispatch(loginUserPreferences('status', 'lunch'))
            start()
        } else if (e === 'coach' && status !== 'coach') {
            dispatch(loginUserPreferences('status', 'coach'))
            start()
        }
        setPopUp(false)
    };

    const handleStatus = (e) => { setPopUp(!popUp) };
    const handlePopUpFeatures = (e) => { setPopUpFeatures(!popUpFeatures) };
    const handlePopUpClose = (e) => { setPopUpClose(!popUpClose) };
    const handlePopUpAssign = (e) => { setPopUpAssign(!popUpAssign) };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("resize", handleResize);
        let fullName = `${loginUser.firstName} ${loginUser.lastName}`
        db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').where("assignedTo", "==", "all")
            //.orderBy("hourUnix")
            .onSnapshot((chats) => {
                let chatsToState = []
                //   setSomeAssigned(false)
                chats.forEach((singleChat) => {
                    let isNewString = ''
                    let isCampaign = singleChat.data().campaign ? singleChat.data().campaign : 'all'
                    let agentCampaigns = loginUser.campaigns ? loginUser.campaigns : []
                    let index = agentCampaigns.findIndex(element => element.id == isCampaign.id);
                    if (singleChat.data().isNew == 'yes' || singleChat.data().isNew == 'no') { isNewString = singleChat.data().isNew } else { isNewString = 'yes' }
                    if (isCampaign == 'all' || index > -1) {
                        chatsToState.push({
                            id: singleChat.id,
                            hour: singleChat.data().hour,
                            idUser: singleChat.data().idUser,
                            nameUser: singleChat.data().nameUser,
                            messages: singleChat.data().messages,
                            channel: singleChat.data().channel,
                            isNew: isNewString,
                            assignedTo: singleChat.data().assignedTo ? singleChat.data().assignedTo : 'all',
                            twitterPostLink: singleChat.data().twitterPostLink ? singleChat.data().twitterPostLink : false,
                            profileUser: singleChat.data().profileUser ? singleChat.data().profileUser : false,
                            campaign: isCampaign,
                        })
                    }
                })
                dispatch(saveMessages(chatsToState.slice()))
                setChats(chatsToState)
            })
        db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').where("assignedTo", "==", fullName)
            //.orderBy("hourUnix")
            .onSnapshot((chatsMe) => {
                let chatsToStateMe = []
                //   setSomeAssigned(false)
                chatsMe.forEach((singleChat) => {
                    let isNewString = ''
                    if (singleChat.data().isNew == 'yes' || singleChat.data().isNew == 'no') { isNewString = singleChat.data().isNew } else { isNewString = 'yes' }
                    chatsToStateMe.push({
                        id: singleChat.id,
                        hour: singleChat.data().hour,
                        idUser: singleChat.data().idUser,
                        nameUser: singleChat.data().nameUser,
                        messages: singleChat.data().messages,
                        channel: singleChat.data().channel,
                        isNew: isNewString,
                        assignedTo: singleChat.data().assignedTo ? singleChat.data().assignedTo : 'all',
                        twitterPostLink: singleChat.data().twitterPostLink ? singleChat.data().twitterPostLink : false,
                        profileUser: singleChat.data().profileUser ? singleChat.data().profileUser : false,
                        campaign: singleChat.data().campaign ? singleChat.data().campaign : 'all',
                    })
                })
                dispatch(saveMessagesMe(chatsToStateMe.slice()))
                setChatsMe(chatsToStateMe)
            })
    }, []);

    const handleClick = async (id, userId, channel) => {
        if (unsuscribe != '') {
            unsuscribe()
        }
        dispatch(saveChatSelected(id))
        dispatch(savePersonSelected(userId))
        dispatch(saveChannel(channel))

        let unsuscribeSnap = db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').doc(id)
            .onSnapshot((singleChat) => {
                if (singleChat.data()) {
                    dispatch(saveMessagesSelected(
                        {
                            id: singleChat.id,
                            hour: singleChat.data().hour,
                            idUser: singleChat.data().idUser,
                            nameUser: singleChat.data().nameUser,
                            messages: singleChat.data().messages,
                            channel: singleChat.data().channel,
                            assignedTo: singleChat.data().assignedTo ? singleChat.data().assignedTo : 'all',
                            twitterPostLink: singleChat.data().twitterPostLink ? singleChat.data().twitterPostLink : false,
                            profileUser: singleChat.data().profileUser ? singleChat.data().profileUser : false,
                            isBot: singleChat.data().isBot ? singleChat.data().isBot : false,
                        }
                    ))
                }
            })
        setUnsuscribe(() => unsuscribeSnap);
    };

    const handleSend = async (e) => {
        e.preventDefault();
        if (channelRedux === 'Telegram' || channelRedux === 'WhatsApp' || channelRedux === 'Messenger' || channelRedux === 'Instagram' || channelRedux === 'Twitter' || channelRedux === 'Twitter-Post') {
            setActive(false);
            //DETERMINA LA HORA
            let date = new Date();
            let hours = date.getHours()
            let hoursString = hours.toString()
            if (hoursString.length === 1) { hoursString = '0' + hoursString }
            let minutes = date.getMinutes()
            let minutesString = minutes.toString()
            if (minutesString.length === 1) { minutesString = '0' + minutesString }
            let hourFinal = hoursString + ":" + minutesString
            //GUARDAR DESDE ACA EN DB//
            //CONVERSION SI ES NECESARIO A NUMEROS. TWITTER QUEDA EN STRING.
            let personFinal = personSelectedState

            if (channelRedux === 'Telegram' || channelRedux === 'WhatsApp' || channelRedux === 'Messenger' || channelRedux === 'Instagram') {
                personFinal = Number(personSelectedState)
            }
            //
            try {
                let chatCurrent = db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').where("idUser", "==", personFinal)
                chatCurrent.get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            let idFinal = doc.id
                            let messages = doc.data().messages
                            messages.push({
                                hour: hourFinal,
                                rol: `${loginUser.firstName} ${loginUser.lastName}`,
                                text: messageTextbox
                            })
                            let chatToUpdate = db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').doc(idFinal)
                            chatToUpdate.update({ messages, isNew: 'no' })
                        });
                    })
                    .catch((error) => {
                        console.log("Error getting document:", error);
                    });
            }
            catch (error) {
                console.log(error);
            }
            const tmp = messageTextbox;
            setMessageTextbox('');
            let urlToSend = ''
            if (channelRedux === 'Telegram') {
                urlToSend = 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_TELEGRAM'
            } else if (channelRedux === 'WhatsApp') {
                urlToSend = 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_WHATSAPP'
            } else if (channelRedux === 'Messenger') {
                urlToSend = 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_MESSENGER'
            } else if (channelRedux === 'Instagram') {
                urlToSend = 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_INSTAGRAM'
            } else if (channelRedux === 'Twitter') {
                urlToSend = 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_TWITTER'
            } else if (channelRedux === 'Twitter-Post') {
                urlToSend = 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_TWITTER_POST'
            }

            const response = await axios({
                url: urlToSend,
                method: 'POST',
                params: {
                    messageToRespond: tmp,
                    personToRespond: personFinal,
                }
            })
            setActive(true);
        }
    }

    const handleAssign = () => {
        let nameAgent = `${loginUser.firstName} ${loginUser.lastName}`
        try {
            let chatToClose = db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').doc(chatSelectedRedux)
            chatToClose.update({ assignedTo: nameAgent })
        }
        catch (error) {
            console.log(error);
        }
        setPopUpAssign(false)
    };

    const handleClose = () => {
        //DETERMINA LA FECHA Y HORA
        let unixTimestamp = new Date().getTime()
        let unixTimestampARG = unixTimestamp
        let date = new Date(unixTimestampARG);
        let hours = date.getHours()
        let hoursString = hours.toString()
        if (hoursString.length === 1) { hoursString = '0' + hoursString }
        let minutes = date.getMinutes()
        let minutesString = minutes.toString()
        if (minutesString.length === 1) { minutesString = '0' + minutesString }
        let seconds = date.getSeconds()
        let secondsString = seconds.toString()
        if (secondsString.length === 1) { secondsString = '0' + secondsString }
        let day = date.getDate()
        let dayString = day.toString()
        if (dayString.length === 1) { dayString = '0' + dayString }
        let month = date.getMonth() + 1
        let monthString = month.toString()
        if (monthString.length === 1) { monthString = '0' + monthString }
        let year = date.getFullYear()
        let dateFinal = hoursString + ":" + minutesString + ":" + secondsString + " " + dayString + "/" + monthString + "/" + year
        //AGREGA LA FECHA Y HORA
        let objToSave = messagesSelectedRedux
        objToSave.closeDate = dateFinal

        try {
            //GUARDA HISTORICO
            db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('history-messages').add(objToSave)
            //Y ELIMINA DE PENDIENTES
            let chatToClose = db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').doc(chatSelectedRedux)
            chatToClose.delete()
        }
        catch (error) {
            console.log(error);
        }
        dispatch(saveChatSelected(''))
        dispatch(savePersonSelected(''))
        dispatch(saveChannel(''))
        dispatch(saveMessages([]))
        dispatch(saveMessagesSelected([]))
        setPopUpClose(false)
    };

    return (
        <>
            <Today
                handleDarkMode={handleDarkMode}
                darkModeState={darkTheme}
                screenSize={screenSize}
                loginUser={loginUser}
                handleReady={handleReady}
                chats={chats}
                chatsMe={chatsMe}
                language={language}
                handleClick={handleClick}
                chatSelected={chatSelected}
                handleSend={handleSend}
                messageTextbox={messageTextbox}
                setMessageTextbox={setMessageTextbox}
                active={active}
                chatsRedux={chatsRedux}
                messagesSelectedRedux={messagesSelectedRedux}
                chatSelectedRedux={chatSelectedRedux}
                messageEl={messageEl}
                handleStatus={handleStatus}
                chartState={popUp}
                status={status}
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                start={start}
                pause={pause}
                isRunning={isRunning}
                handleClose={handleClose}
                popUpFeatures={popUpFeatures}
                popUpClose={popUpClose}
                popUpAssign={popUpAssign}
                handlePopUpFeatures={handlePopUpFeatures}
                handlePopUpClose={handlePopUpClose}
                handlePopUpAssign={handlePopUpAssign}
                someAssigned={someAssigned}
                handleAssign={handleAssign}
            />
            <Sidebar />
        </>
    );
}

export default TodayClient;