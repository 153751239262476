import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import PopUpNotification from "../PopUpNotification";
import Confetti from 'react-confetti'

export default ({ screenSize, darkModeState, language, loader, handleChange, handleSubmit, name, phoneNumber, documentNumber, message, campaignReturn, handleLogout, popUpNotificationState, handlePopUpNotification, templateSelected, setTemplateSelected, clientName }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            // padding: `${screenSize > 800 ? ("3% 2%") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        buttonNavbar: {
            display: "flex",
            // alignItems: "flex-start",
            // justifyContent: 'flex-start',
            alignSelf: 'center',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '12px 32px',
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        campaigns: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        form: {
            width: '100%',
            // margin: '20px 0px'
        },

        label: {
            margin: '15px 0px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 0px 10px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        templatesDiv: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 0px 10px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        templatesDivSelected: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `#fff`,
            backgroundColor: '#4dc2f1',
            margin: '0px 0px 10px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        buttonNew: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: '25px 0px',
            padding: '12px 32px',
        },
        // typeCampaign: {
        //     display: "flex",
        //     flex: 3,
        //     color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
        //     margin: 0,
        //     fontSize: "14px",
        //     fontFamily: "InterBold",
        //     // textAlign: 'end'
        //     alignSelf: 'center',
        //     justifyContent: 'flex-end'
        // },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        }
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === 'english' ? ('New Message') : ('Nuevo Mensaje')} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === 'english' ? ('Create a new message.') : ('Crea un nuevo mensaje saliente.')}</h3>
                    </div>
                    {/* <button style={styles.buttonNavbar} onClick={() => handleLogout()}>Cerrar Sesión</button> */}
                </div>
                <div style={styles.campaigns}>
                    {loader ? (
                        <div style={styles.containerLoader}>
                            <Loader
                                style={styles.loader}
                                type="TailSpin"
                                color="#4dc2f1"
                                height={100}
                                width={100}
                                timeout={10000}
                            />
                        </div>
                    ) : (
                        <>
                            {popUpNotificationState === true ? (
                                <>
                                    <Confetti numberOfPieces={200} gravity={0.2} recycle={false} width={window.screen.width} height={window.screen.height} />
                                    <PopUpNotification
                                        screenSize={screenSize}
                                        darkModeState={darkModeState}
                                        language={language}
                                        handlePopUpNotification={handlePopUpNotification}
                                    />
                                </>
                            ) : (<></>)}
                            <form onSubmit={(e) => handleSubmit(e)} style={styles.form}>
                                <div className="form-group">
                                    <label style={styles.label}>{language === 'english' ? ('Phone number') : ('Número de teléfono')}</label>
                                    <input onChange={(e) => handleChange(e, 'phoneNumber')} name="phoneNumber" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={phoneNumber} placeholder='5491155805544' required />

                                    <label style={styles.label}>{language === 'english' ? ('Message') : ('Template para enviar')}</label>
                                    {/* <input onChange={(e) => handleChange(e, 'message')} name="message" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={message} placeholder={language === 'english' ? ('Message to the client...') : ('Mensaje para el cliente...')} required /> */}
                                    {/* {templateSelected == "0800_a_whatsapp" ? (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDivSelected}>0800 a WhatsApp</div>) : (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDiv} onClick={() => { setTemplateSelected("0800_a_whatsapp") }}>0800 a WhatsApp</div>)} */}
                                    {templateSelected == "pyme_generico" ? (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDivSelected}>Pyme - Novedades</div>) : (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDiv} onClick={() => { setTemplateSelected("pyme_generico") }}>Pyme - Novedades</div>)}
                                    {templateSelected == "pyme_fotos" ? (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDivSelected}>Pyme - Pedido fotos</div>) : (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDiv} onClick={() => { setTemplateSelected("pyme_fotos") }}>Pyme - Pedido Fotos</div>)}
                                    {templateSelected == "pyme_bienvenida_1" ? (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDivSelected}>Pyme - Bienvenida</div>) : (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDiv} onClick={() => { setTemplateSelected("pyme_bienvenida_1") }}>Pyme - Bienvenida</div>)}

                                    {templateSelected == "pyme_novedades_siniestros" ? (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDivSelected}>Pyme - Novedades de siniestros</div>) : (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDiv} onClick={() => { setTemplateSelected("pyme_novedades_siniestros") }}>Pyme - Novedades de siniestros</div>)}
                                    {templateSelected == "solicitud_pyme" ? (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDivSelected}>Pyme - Pólizas no vigentes</div>) : (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDiv} onClick={() => { setTemplateSelected("solicitud_pyme") }}>Pyme - Pólizas no vigentes</div>)}



                                    {templateSelected == "pyme_generico" || templateSelected == "pyme_fotos" || templateSelected == "pyme_bienvenida_1" || templateSelected == "pyme_novedades_siniestros" || templateSelected == "solicitud_pyme" ? (
                                        <>
                                            <label style={styles.label}>{language === 'english' ? ('Client Name') : ('Nombre de Cliente')}</label>
                                            <input onChange={(e) => handleChange(e, 'clientName')} name="clientName" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={clientName} placeholder={language === 'english' ? ('Name of the client...') : ('Nombre del cliente...')} required />

                                            <label style={styles.label}>{language === 'english' ? ('Document Number') : ('Número de Documento')}</label>
                                            <input onChange={(e) => handleChange(e, 'documentNumber')} name="documentNumber" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={documentNumber} placeholder={language === 'english' ? ('Document number of the client...') : ('Número de documento del cliente...')} required />
                                        </>
                                    ) : (<></>)}

                                    <label style={styles.label}>{language === 'english' ? ('Campaign') : ('Campaña de retorno')}</label>
                                    <input
                                        // onChange={(e) => handleChange(e, 'campaignReturn')}
                                        name="campaignReturn" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={campaignReturn}
                                        // placeholder={language === 'english' ? ('Name of the new campaign...') : ('Nombre de la campaña de retorno...')} 
                                        readonly />

                                    <button type="submit" className="btn" style={styles.buttonNew}>{language === 'english' ? ('Send message') : ('Enviar mensaje')}</button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
