import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import PopUpNotification from "../PopUpNotification";
import PopUpMessageChannelOut from "../PopUpMessageChannelOut";
import Confetti from 'react-confetti'

export default ({ screenSize, darkModeState, language, loader, handleChange, handleSubmit, name, phoneNumber, documentNumber, message, campaignReturn, handleLogout, popUpNotificationState, handlePopUpNotification, templateSelected, setTemplateSelected, clientName, popUpMessageChannelOutState, handlePopUpChannel, handleChannel, channelOut, loginUser, templatesWhatsApp }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            // padding: `${screenSize > 800 ? ("3% 2%") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        buttonNavbar: {
            display: "flex",
            // alignItems: "flex-start",
            // justifyContent: 'flex-start',
            alignSelf: 'center',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '12px 32px',
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        campaigns: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        form: {
            width: '100%',
            // margin: '20px 0px'
        },

        label: {
            margin: '15px 0px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 0px 10px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        inputTextarea: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 0px 10px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '15px 20px'
        },
        templatesDiv: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 0px 10px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        templatesDivSelected: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `#fff`,
            backgroundColor: '#4dc2f1',
            margin: '0px 0px 10px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        buttonNew: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: '25px 0px',
            padding: '12px 32px',
        },
        buttonChannel: {
            display: 'flex',
            flex: 12,
            margin: "0px 0px 10px",
            fontSize: "18px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            fontFamily: "InterRegular",
            lineHeight: '1.4',
            alignItems: 'center',
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ('hsl(0, 0%, 100%)')}`,
            border: 'none',
            outline: 'none',
            padding: 0
        },
        imageChannel: {
            height: 40,
            margin: '0px 20px 0px 0px',
        },
        // typeCampaign: {
        //     display: "flex",
        //     flex: 3,
        //     color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
        //     margin: 0,
        //     fontSize: "14px",
        //     fontFamily: "InterBold",
        //     // textAlign: 'end'
        //     alignSelf: 'center',
        //     justifyContent: 'flex-end'
        // },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        }
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === 'english' ? ('New Message') : ('Nuevo Mensaje')} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === 'english' ? ('Create a new message.') : ('Crea un nuevo mensaje saliente.')}</h3>
                    </div>
                    {/* <button style={styles.buttonNavbar} onClick={() => handleLogout()}>Cerrar Sesión</button> */}
                </div>
                <div style={styles.campaigns}>
                    {loader ? (
                        <div style={styles.containerLoader}>
                            <Loader
                                style={styles.loader}
                                type="TailSpin"
                                color="#4dc2f1"
                                height={100}
                                width={100}
                                timeout={10000}
                            />
                        </div>
                    ) : (
                        <>
                            {popUpNotificationState === true ? (
                                <>
                                    <Confetti numberOfPieces={200} gravity={0.2} recycle={false} width={window.screen.width} height={window.screen.height} />
                                    <PopUpNotification
                                        screenSize={screenSize}
                                        darkModeState={darkModeState}
                                        language={language}
                                        handlePopUpNotification={handlePopUpNotification}
                                    />
                                </>
                            ) : (<></>)}



                            {popUpMessageChannelOutState === true ? (
                                <PopUpMessageChannelOut
                                    screenSize={screenSize}
                                    darkModeState={darkModeState}
                                    language={language}
                                    handlePopUpChannel={handlePopUpChannel}
                                    handleChannel={handleChannel}
                                    loginUser={loginUser}
                                />
                            ) : (<></>)}



                            <form onSubmit={(e) => handleSubmit(e)} style={styles.form}>
                                <div className="form-group">

                                    <label style={styles.label}>{language === 'english' ? ('Channel') : ('Canal')}</label>

                                    {channelOut == "WhatsApp" ? (<div style={styles.buttonChannel} onClick={(e) => handlePopUpChannel()}><img src="https://i.postimg.cc/nLzTzgVC/whatsapp-logo.png" style={styles.imageChannel} />WhatsApp</div>) : (<></>)}
                                    {channelOut == "Telegram" ? (<div style={styles.buttonChannel} onClick={(e) => handlePopUpChannel()}><img src="https://i.postimg.cc/ydJHFLjN/telegram.png" style={styles.imageChannel} />Telegram</div>) : (<></>)}
                                    {channelOut == "SMS" ? (<div style={styles.buttonChannel} onClick={(e) => handlePopUpChannel()}><img src="https://i.postimg.cc/3JHq72NV/sms-logo.png" style={styles.imageChannel} />SMS</div>) : (<></>)}
                                    {channelOut == "Email" ? (<div style={styles.buttonChannel} onClick={(e) => handlePopUpChannel()}><img src="https://i.postimg.cc/nVKk4gVS/email-logo.png" style={styles.imageChannel} />Email</div>) : (<></>)}

                                    {/* 
                                    <input onClick={(e) => handlePopUpChannel()} name="channel" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={channelOut} placeholder='Canal' required />
 */}





                                    {channelOut == "WhatsApp" || channelOut == "SMS" ? (
                                        <>
                                            <label style={styles.label}>{language === 'english' ? ('Phone number') : ('Número de teléfono')}</label>
                                            <input onChange={(e) => handleChange(e, 'phoneNumber')} name="phoneNumber" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={phoneNumber} placeholder='5491155805544' required />
                                        </>
                                    ) : (<></>)}
                                    {channelOut == "Telegram" ? (
                                        <>
                                            <label style={styles.label}>{language === 'english' ? ('User number') : ('Número de usuario')}</label>
                                            <input onChange={(e) => handleChange(e, 'phoneNumber')} name="phoneNumber" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={phoneNumber} placeholder='1203988292' required />
                                        </>
                                    ) : (<></>)}
                                    {channelOut == "Email" ? (
                                        <>
                                            <label style={styles.label}>{language === 'english' ? ('Email') : ('Correo electrónico')}</label>
                                            <input onChange={(e) => handleChange(e, 'phoneNumber')} name="phoneNumber" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={phoneNumber} placeholder='example@mail.com' required />
                                        </>
                                    ) : (<></>)}








                                    {channelOut == "WhatsApp" ? (<>

                                        <label style={styles.label}>{language === 'english' ? ('Template to send') : ('Template para enviar')}</label>



                                        {templatesWhatsApp.length > 0 ? (<>
                                            {templatesWhatsApp.map((singleTemplate) => {
                                                return (<>
                                                    {/* {templateSelected == singleTemplate.name ? (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDivSelected}>{singleTemplate.name}</div>) : (<div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.templatesDiv} onClick={() => { setTemplateSelected("pyme_generico") }}>{singleTemplate.name}</div>)} */}
                                                    <div className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={templateSelected == singleTemplate.name ? (styles.templatesDivSelected) : (styles.templatesDiv)} onClick={() => { setTemplateSelected(singleTemplate.name) }}>{singleTemplate.name}</div>
                                                </>)
                                            })}
                                        </>) : (<></>)}









                                    </>) : (<></>)}

                                    {channelOut == "SMS" || channelOut == "Telegram" || channelOut == "Email" ? (<>
                                        <label style={styles.label}>{language === 'english' ? ('Message') : ('Mensaje')}</label>
                                        <textarea onChange={(e) => handleChange(e, 'message')} name="message" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.inputTextarea} value={message} placeholder={language === 'english' ? ('Hello...') : ('Hola...')} required />


                                    </>) : (<></>)}




                                    {channelOut == "Email" ? (<>


                                    </>) : (<></>)}






















                                    {/* <label style={styles.label}>{language === 'english' ? ('Campaign') : ('Campaña de retorno')}</label>
                                    <input
                                        // onChange={(e) => handleChange(e, 'campaignReturn')}
                                        name="campaignReturn" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={campaignReturn}
                                        // placeholder={language === 'english' ? ('Name of the new campaign...') : ('Nombre de la campaña de retorno...')} 
                                        readonly /> */}


                                    <button type="submit" className="btn" style={styles.buttonNew}>{language === 'english' ? ('Send message') : ('Enviar mensaje')}</button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
