import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/actions/loginAction";
import AuthService from "../services/auth.service";
import { loginUserPreferences } from "../store/actions/loginAction";
import { saveMessagesSelected, saveChatSelected, savePersonSelected, saveChannel } from "../store/actions/messageAction";

const SidebarContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const loginUser = useSelector(state => state.user.loginUser)
  const language = useSelector(state => state.user.language)
  const darkTheme = useSelector(state => state.user.darkTheme)

  const handleProfile = (e) => {
    history.push("/profile");
  };

  const handleLogout = (e) => {
    AuthService.logout()
    dispatch(logoutUser())
    dispatch(loginUserPreferences('status', 'pending'))
    dispatch(saveChatSelected(''))
    dispatch(savePersonSelected(''))
    dispatch(saveChannel(''))
    dispatch(saveMessagesSelected([]))
    history.push("/login");
  };

  return (
    <Sidebar
      handleLogout={handleLogout}
      handleProfile={handleProfile}
      loginUser={loginUser}
      darkModeState={darkTheme}
      language={language}
    />
  );
}

export default SidebarContainer;
