import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import reducer from "./reducers";

const persistConfig = {
  key: "insideOne",
  storage, // defaults to localStorage for web
  whitelist: ["user", "message"],
  //blacklist: ["saveLoginClient", "assetsArray"],
};

const reducerPersisted = persistReducer(persistConfig, reducer); //Aca le decimos que Reducer queremos persistir en la app.

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducerPersisted,
  composeEnhancers(applyMiddleware(thunkMiddleware))
  //REEMPLAZAR PARA DEBUGUEAR
  //composeEnhancers(applyMiddleware(createLogger(), thunkMiddleware))
);

const persistor = persistStore(store);

export { persistor, store };
