const initialState = {
    messageSelected: [],
    messages: [],
    messagesMe: [],
    chatId: '',
    userId: '',
    channel: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_MESSAGES_SELECTED":
            //  console.log('como llega al reducer:', action.messages)
            return Object.assign({}, state, {
                messageSelected: action.messages,
            });
        case "SET_MESSAGES":
            return Object.assign({}, state, {
                messages: action.messages,
            });
        case "SET_MESSAGES_ME":
            return Object.assign({}, state, {
                messagesMe: action.messages,
            });
        case "SET_CHAT_SELECTED":
            // console.log('como llega al reducer:', action.chatId)
            return Object.assign({}, state, {
                chatId: action.chatId,
            });
        case "SET_PERSON_SELECTED":
            return Object.assign({}, state, {
                userId: action.userId,
            });
        case "SET_CHANNEL_SELECTED":
            return Object.assign({}, state, {
                channel: action.channel,
            });
        default:
            return state;
    }
};
