import React from "react";
import Confetti from 'react-confetti'
import PopUpStatus from "./PopUpStatus";
import PopUpFeatures from "./PopUpFeatures";
import PopUpClose from "./PopUpClose";
import PopUpAssign from "./PopUpAssign";

export default ({ screenSize, handleDarkMode, loginUser, darkModeState, messageEl, handleReady, chats, chatsMe, language, handleClick, chatSelectedRedux, messageTextbox, setMessageTextbox, active, handleSend, handleStatus, chartState, status, hours, minutes, seconds, messagesSelectedRedux, handleClose, popUpFeatures, handlePopUpFeatures, someAssigned, handlePopUpClose, popUpClose, popUpAssign, handlePopUpAssign, handleAssign }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            margin: 0,
            height: "100%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
            alignItems: 'flex-start'
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            border: 'none',
            outline: 'none',
            padding: 0
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
        },
        containerNoReady: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '80%'
        },
        notReady: {
            display: 'flex',
            alignSelf: 'center',
            marginTop: '30px',
            fontSize: '20px',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`
        },
        noChats: {
            display: 'flex',
            alignSelf: 'center',
            fontSize: '16px',
            fontFamily: 'InterRegular',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`
        },
        formButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '12px 32px',
            margin: "30px 0px 0px",
            color: '#fff',
            outline: 0,
        },
        chatContainer: {
            // backgroundColor: "green",
            height: "80vh",
            borderRadius: "8px",
            display: "flex",
            // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
            boxShadow: `${darkModeState ? ("0 3px 16px 0 hsl(228, 28%, 11%)") : ("0 3px 16px 0 rgba(90, 90, 90, 0.2)")}`,
        },
        chatSessions: {
            flex: 3,
            display: "flex",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            borderRadius: "10px 0 0 10px",
            flexDirection: "column",
            borderRight: "solid 1px rgba(90,90,90,0.1)",
        },
        chatSessionsAll: {
            flex: 6,
            display: "flex",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            borderRadius: "10px 0 0 10px",
            flexDirection: "column",
            borderRight: "solid 1px rgba(90,90,90,0.1)",
            overflow: "auto",
            // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
        },
        chatSessionsMe: {
            flex: 6,
            display: "flex",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            borderRadius: "10px 0 0 10px",
            flexDirection: "column",
            borderRight: "solid 1px rgba(90,90,90,0.1)"
        },
        chatSessionsTitle: {
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "10px 10px 10px 20px",
        },
        chatNoSessions: {
            flex: 3,
            display: "flex",
            justifyContent: 'center',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            borderRadius: "10px 0 0 10px",
            flexDirection: "column",
            borderRight: "solid 1px rgba(90,90,90,0.1)",
            // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
        },
        chatSession: {
            margin: "10px 10px 0px 10px",
            display: "flex",
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(227, 47%, 96%)")}`,
            borderRadius: "8px",
            flexDirection: "row",
            border: "none",
            overflow: "auto",
            // padding: '6px 0px'
        },
        chatChannel: {
            display: "flex",
            flex: 3,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        chatChannelImage: {
            display: "flex",
            alignSelf: 'center',
            height: 40,
        },
        chatInformation: {
            display: "flex",
            flex: 9,
            alignItems: 'flex-start',
            flexDirection: "column"
        },
        chatSessionName: {
            margin: "10px 10px 0px 10px",
            display: "flex",
            fontSize: 12,
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            alignItems: 'center'
        },
        notification: {
            display: "flex",
            alignSelf: 'center',
            height: 8,
            margin: '0px 4px'
        },
        chatSessionText: {
            margin: "10px 10px 10px 10px",
            display: "flex",
            fontSize: 14,
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            whiteSpace: 'initial',
            textAlign: 'start',
            //lineBreak: 'anywhere'
        },
        chatSessionHour: {
            margin: "0px 10px 10px 10px",
            display: "flex",
            fontSize: 10,
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
        },
        chatReceived: {
            display: "flex",
            flexDirection: 'column',
            margin: '0px 10px',
            padding: "15px 20px",
            maxWidth: "60%",
            alignSelf: "flex-start",
            alignItems: "flex-end",
            justifyContent: 'center',
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(227, 47%, 96%)")}`,
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            //boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
            borderRadius: "10px 10px 10px 0px",
            fontFamily: 'InterRegular'
        },
        hourOfChatReceived: {
            display: 'flex',
            margin: "8px 20px 10px 20px",
            alignSelf: "flex-start",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: 'InterRegular',
            fontSize: 12
        },
        chatSent: {
            display: 'flex',
            flexDirection: 'column',
            padding: "15px 20px",
            maxWidth: "60%",
            margin: '0px 10px',
            alignSelf: "flex-end",
            alignItems: "flex-end",
            justifyContent: 'center',
            backgroundColor: "#4dc2f1",
            color: "#fff",
            //boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
            borderRadius: "10px 10px 0px",
            fontFamily: 'InterRegular'
        },
        hourOfChatSent: {
            display: 'flex',
            margin: "8px 20px 10px",
            alignSelf: "flex-end",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: 'InterRegular',
            fontSize: 12
        },
        chatMessages: {
            flex: 8,
            display: "flex",
            //backgroundColor: "#fff",
            borderRadius: "0 10px 10px 0",
            flexDirection: "column",
            height: '100%'
        },
        chatAll: {
            flex: 11,
            display: "flex",
            flexDirection: "column",
            height: '50%'
        },
        chatNoMessagesAll: {
            flex: 11,
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column",
            borderRadius: "0 10px 10px 0",
        },
        chatMessagesActions: {
            flex: 1,
            //  height: '150px',
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(227, 47%, 96%)")}`,
            // backgroundColor: 'white',
            // boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
            borderRadius: "8px",
            padding: "0px 20px",
            margin: '10px 10px 10px 10px',
        },
        singleMessages: {
            display: "flex",
            flex: 11,
            overflow: "auto",
            flexDirection: "column",
        },
        userInformation: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center'
        },
        chatChannelSingle: {
            display: "flex",
            // flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        chatChannelImageSingle: {
            display: "flex",
            alignSelf: 'center',
            height: 20,
        },

        nameUser: {
            color: `${darkModeState ? ("#fff") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 10px'
        },
        buttonActionsGroup: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center'
        },
        buttonActions: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(227, 47%, 96%)")}`,
            border: "none",
            color: '#fff',
            outline: 0,
            padding: '0px 10px'
        },
        iconActions: {
            width: '20px',
            height: '20px',
            color: `${darkModeState ? ("#fff") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 10px 0px 0px',
        },
        textActions: {
            color: '#fff',
            margin: '0px',
            fontSize: '12px',
            fontFamily: 'InterRegular',
            margin: '0px',
            color: `${darkModeState ? ("#fff") : ("hsl(228, 12%, 44%)")}`,
        },
        textBoxChat: {
            flex: 1,
            display: "flex",
            borderRadius: "0px 10px 10px 10px",
            borderTop: `${darkModeState ? ("solid 1px rgba(90,90,90,0.3)") : ("solid 0.2px rgb(240,240,240)")}`,
            //  flexDirection: "row",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        inputChat: {
            display: "flex",
            flex: 10,
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            border: "0px",
            padding: '15px 20px',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: 'InterRegular',
            fontSize: "16px",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        textChat: {
            margin: "15px",
            fontSize: "16px",
            color: "black",
        },
        button: {
            display: "flex",
            flex: 2,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            backgroundColor: "#4dc2f1",
            height: "100%",
            border: "0px",
            color: '#fff',
            outline: 0,
            fontSize: "16px",
            fontFamily: 'InterRegular',
        },
        textButton: {
            fontSize: "16px",
            color: "black",
        },
        counter: { textAlign: 'center' },
        textCounter: {
            fontSize: '100px',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
        },
        buttonFloat: {
            position: "absolute",
            bottom: 0,
            left: 250,
            display: "flex",
            justifyContent: "flex-end",
            height: '280px'
        }
    };

    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (`${month}`.length === 1) { month = "0" + month }
    let date = new Date().getDate()
    if (`${date}`.length === 1) { date = "0" + date }
    let dateTotal = (date + " / " + month + " / " + year)

    return (
        <>
            {status === 'ready' ? (
                <Confetti numberOfPieces={200} gravity={0.1} recycle={false} width={window.screen.width} height={window.screen.height} />
            ) : (<></>)}
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        {status === 'ready' ? (
                            <button style={styles.titleNavbar} onClick={handleStatus}><>{language === 'english' ? (<>You are <span style={{ color: '#4dc2f1' }}>ready</span> 💻</>) : (<>Estás <span style={{ color: '#4dc2f1' }}>disponible</span> 💻</>)}</></button>
                        ) : (<>
                            {status === 'break' ? (
                                <button style={styles.titleNavbar} onClick={handleStatus}><>{language === 'english' ? (<>Good <span style={{ color: '#4dc2f1' }}>break</span> ☕️</>) : (<>Buen <span style={{ color: '#4dc2f1' }}>descanso</span> ☕️</>)}</></button>
                            ) : (<>
                                {status === 'lunch' ? (
                                    <button style={styles.titleNavbar} onClick={handleStatus}><>{language === 'english' ? (<>Good <span style={{ color: '#4dc2f1' }}>lunch</span> 🍔</>) : (<>Buen <span style={{ color: '#4dc2f1' }}>almuerzo</span> 🍔</>)}</></button>
                                ) : (<>
                                    {status === 'coach' ? (
                                        <button style={styles.titleNavbar} onClick={handleStatus}><>{language === 'english' ? (<>At <span style={{ color: '#4dc2f1' }}>meeting</span> 🗓</>) : (<>Estás <span style={{ color: '#4dc2f1' }}>en reunión</span> 🗓</>)}</></button>
                                    ) : (
                                        <h3 style={styles.titleNavbar}>{language === 'english' ? ('Today is a new day') : ('Hoy es un nuevo día')} 🎉</h3>
                                    )}
                                </>)}
                            </>)}
                        </>)}
                        <h3 style={styles.subtitleNavbar}>{dateTotal}</h3>
                    </div>
                    <div className={darkModeState ? ("textDarkToogle") : ("textLightToogle")} style={styles.toogleNavbar}>
                        {darkModeState === true ? (<button style={styles.textToogle} onClick={handleDarkMode}><>{language === 'english' ? ('Light Mode') : ('Tema Claro')}</></button>) : (<button style={styles.textToogle} onClick={handleDarkMode}>{language === 'english' ? ('Dark Mode') : ('Tema Oscuro')}</button>)}
                    </div>
                </div>
                <div style={styles.indicators}>
                    {chartState === true ? (
                        <PopUpStatus
                            darkModeState={darkModeState}
                            screenSize={screenSize}
                            handleReady={handleReady}
                            handleStatus={handleStatus}
                        />
                    ) : (<></>)}
                    {popUpFeatures === true ? (
                        <PopUpFeatures
                            screenSize={screenSize}
                            darkModeState={darkModeState}
                            language={language}
                            handlePopUpFeatures={handlePopUpFeatures}
                        />
                    ) : (<></>)}
                    {popUpClose === true ? (
                        <PopUpClose
                            screenSize={screenSize}
                            darkModeState={darkModeState}
                            language={language}
                            handlePopUpClose={handlePopUpClose}
                            handleClose={handleClose}
                        />
                    ) : (<></>)}
                    {popUpAssign === true ? (
                        <PopUpAssign
                            screenSize={screenSize}
                            darkModeState={darkModeState}
                            language={language}
                            handlePopUpAssign={handlePopUpAssign}
                            handleAssign={handleAssign}
                        />
                    ) : (<></>)}
                    {status === 'ready' ? (
                        <div style={styles.chatContainer}>
                            <div style={styles.chatSessions}>
                                <div style={styles.chatSessionsAll}>
                                    {/* {someAssigned != false ? ( */}
                                    {chats.length > 0 ? (
                                        <div style={styles.chatSessions}>
                                            {chats.map((singleChat) => {
                                                if (singleChat.messages.length && (singleChat.assignedTo == 'all' || singleChat.assignedTo == `${loginUser.firstName} ${loginUser.lastName}` || loginUser.rol == "Supervisor")) {
                                                    return (
                                                        <button key={singleChat.id} type="submit"
                                                            className="btn btn-primary"
                                                            style={styles.chatSession} onClick={(e) => handleClick(singleChat.id, singleChat.idUser, singleChat.channel)}>
                                                            <div style={styles.chatChannel}>
                                                                {singleChat.channel === 'Telegram' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/FsHkQFkY/telegram.png" alt="Icon of Telegram" />) : (<></>)}
                                                                {singleChat.channel === 'WhatsApp' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/FFPVxzxT/whatsapp.png" alt="Icon of WhatsApp" />) : (<></>)}
                                                                {singleChat.channel === 'Instagram' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/vZLw66bb/instagram.png" alt="Icon of WhatsApp" />) : (<></>)}
                                                                {singleChat.channel === 'Messenger' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/br7f1xZN/messenger.png" alt="Icon of WhatsApp" />) : (<></>)}
                                                                {singleChat.channel === 'Facebook' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/fTJG5rs2/facebook.png" alt="Icon of WhatsApp" />) : (<></>)}
                                                                {singleChat.channel === 'Twitter' || singleChat.channel === 'Twitter-Post' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/KvVzR7Z5/twitter.png" alt="Icon of Twitter" />) : (<></>)}
                                                            </div>
                                                            <div style={styles.chatInformation}>
                                                                <h6 style={styles.chatSessionName}>
                                                                    {singleChat.nameUser != 'Unknown' ? (singleChat.nameUser) : (<>{language === 'english' ? (singleChat.nameUser) : ('Desconocido')}</>)} {singleChat.isNew == 'yes' ? <span><img style={styles.notification} src="https://i.postimg.cc/QM1T5skm/notification.png" alt="Notification of new message" /></span> : ''}
                                                                </h6>
                                                                {singleChat.messages[singleChat.messages.length - 1].text.length > 45 ? (
                                                                    <h6 style={styles.chatSessionText}>
                                                                        {singleChat.messages[singleChat.messages.length - 1].text.slice(0, 44) + "..."}
                                                                    </h6>
                                                                ) : (
                                                                    <h6 style={styles.chatSessionText}>
                                                                        {singleChat.messages[singleChat.messages.length - 1].text}
                                                                    </h6>
                                                                )}
                                                                <h6 style={styles.chatSessionHour}>{singleChat.hour.slice(0, 5)}</h6>
                                                            </div>
                                                        </button>
                                                    );
                                                }
                                            })}
                                        </div>
                                    ) : (
                                        <div style={styles.chatNoSessions}>
                                            <div style={styles.noChats}>{language === 'english' ? ('No chat pending') : ('Sin chats pendientes')}</div>
                                        </div>
                                    )}
                                </div>
                                {chatsMe.length > 0 ? (
                                    <div style={styles.chatSessionsMe}>
                                        {/* {someAssigned != false ? ( */}
                                        <div style={styles.chatSessionsTitle}>{language === 'english' ? (`Assigned to me`) : (`Asignado a mi`)}</div>
                                        <div style={styles.chatSessions}>
                                            {chatsMe.map((singleChat) => {
                                                if (singleChat.messages.length && (singleChat.assignedTo == 'all' || singleChat.assignedTo == `${loginUser.firstName} ${loginUser.lastName}` || loginUser.rol == "Supervisor")) {
                                                    return (
                                                        <button key={singleChat.id} type="submit" className="btn btn-primary" style={styles.chatSession} onClick={(e) => handleClick(singleChat.id, singleChat.idUser, singleChat.channel)}>
                                                            <div style={styles.chatChannel}>
                                                                {singleChat.channel === 'Telegram' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/FsHkQFkY/telegram.png" alt="Icon of Telegram" />) : (<></>)}
                                                                {singleChat.channel === 'WhatsApp' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/FFPVxzxT/whatsapp.png" alt="Icon of WhatsApp" />) : (<></>)}
                                                                {singleChat.channel === 'Instagram' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/vZLw66bb/instagram.png" alt="Icon of Instagram" />) : (<></>)}
                                                                {singleChat.channel === 'Messenger' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/br7f1xZN/messenger.png" alt="Icon of Messenger" />) : (<></>)}
                                                                {singleChat.channel === 'Facebook' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/fTJG5rs2/facebook.png" alt="Icon of Facebook" />) : (<></>)}
                                                                {singleChat.channel === 'Twitter' || singleChat.channel === 'Twitter-Post' ? (<img style={styles.chatChannelImage} src="https://i.postimg.cc/KvVzR7Z5/twitter.png" alt="Icon of Twitter" />) : (<></>)}
                                                            </div>
                                                            <div style={styles.chatInformation}>
                                                                <h6 style={styles.chatSessionName}>
                                                                    {singleChat.nameUser != 'Unknown' ? (singleChat.nameUser) : (<>{language === 'english' ? (singleChat.nameUser) : ('Desconocido')}</>)} {singleChat.isNew == 'yes' ? <span><img style={styles.notification} src="https://i.postimg.cc/QM1T5skm/notification.png" alt="Notification of new message" /></span> : ''}
                                                                </h6>
                                                                {singleChat.messages[singleChat.messages.length - 1].text.length > 45 ? (
                                                                    <h6 style={styles.chatSessionText}>
                                                                        {singleChat.messages[singleChat.messages.length - 1].text.slice(0, 44) + "..."}
                                                                    </h6>
                                                                ) : (
                                                                    <h6 style={styles.chatSessionText}>
                                                                        {singleChat.messages[singleChat.messages.length - 1].text}
                                                                    </h6>
                                                                )}
                                                                <h6 style={styles.chatSessionHour}>{singleChat.hour.slice(0, 5)}</h6>
                                                            </div>
                                                        </button>
                                                    );
                                                }
                                            })}


                                        </div>
                                        {/* ) : (
                                            <div style={styles.chatNoSessions}>
                                                <div style={styles.noChats}>{language === 'english' ? ('No chat pending') : ('Sin chats pendientes')}</div>
                                            </div>
                                        )} */}
                                    </div>
                                ) : (<></>)}

                            </div>
                            <div style={styles.chatMessages} >
                                {messagesSelectedRedux && messagesSelectedRedux.nameUser && chatSelectedRedux !== '' ? (
                                    <div style={styles.chatAll} >
                                        {/* {console.log('entre en component')} */}
                                        <div style={styles.chatMessagesActions}>
                                            <div style={styles.userInformation}>
                                                <div style={styles.chatChannelSingle}>
                                                    {messagesSelectedRedux.channel === 'Telegram' ? (<img style={styles.chatChannelImageSingle} src="https://i.postimg.cc/FsHkQFkY/telegram.png" alt="Icon of Telegram" />) : (<></>)}
                                                    {messagesSelectedRedux.channel === 'WhatsApp' ? (<img style={styles.chatChannelImageSingle} src="https://i.postimg.cc/FFPVxzxT/whatsapp.png" alt="Icon of WhatsApp" />) : (<></>)}
                                                    {messagesSelectedRedux.channel === 'Instagram' ? (<img style={styles.chatChannelImageSingle} src="https://i.postimg.cc/vZLw66bb/instagram.png" alt="Icon of Instagram" />) : (<></>)}
                                                    {messagesSelectedRedux.channel === 'Messenger' ? (<img style={styles.chatChannelImageSingle} src="https://i.postimg.cc/br7f1xZN/messenger.png" alt="Icon of Messenger" />) : (<></>)}
                                                    {messagesSelectedRedux.channel === 'Facebook' ? (<img style={styles.chatChannelImageSingle} src="https://i.postimg.cc/fTJG5rs2/facebook.png" alt="Icon of Facebook" />) : (<></>)}
                                                    {messagesSelectedRedux.channel === 'Twitter' || messagesSelectedRedux.channel === 'Twitter-Post' ? (<img style={styles.chatChannelImageSingle} src="https://i.postimg.cc/KvVzR7Z5/twitter.png" alt="Icon of Twitter" />) : (<></>)}
                                                </div>
                                                {/* <span style={styles.nameUser}>{messagesSelectedRedux.nameUser != 'Unknown' ? (messagesSelectedRedux.nameUser) : (<>{language === 'english' ? (messagesSelectedRedux.nameUser) : ('Desconocido')}</>)}</span> */}
                                                <span style={styles.nameUser}>{messagesSelectedRedux.nameUser != 'Unknown' ? (<>{messagesSelectedRedux.channel === 'Twitter' || messagesSelectedRedux.channel === 'Twitter-Post' ? (`${messagesSelectedRedux.nameUser} (@${messagesSelectedRedux.profileUser})`) : (messagesSelectedRedux.nameUser)}</>) : (<>{language === 'english' ? (messagesSelectedRedux.nameUser) : ('Desconocido')}</>)}</span>
                                            </div>
                                            {messagesSelectedRedux.assignedTo == `${loginUser.firstName} ${loginUser.lastName}` ? (
                                                <div style={styles.buttonActionsGroup}>
                                                    {messagesSelectedRedux.channel === 'Twitter-Post' ? (
                                                        <button style={styles.buttonActions} onClick={() => (window.open(messagesSelectedRedux.twitterPostLink))}>
                                                            <svg style={styles.iconActions} fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                            </svg>
                                                            <span style={styles.textActions}>{language === 'english' ? ('Open Tweet') : ('Abrir Tweet')}</span>
                                                        </button>
                                                    ) : (<></>)}
                                                    <button style={styles.buttonActions} onClick={handlePopUpFeatures}>
                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-shuffle" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z" />
                                                            <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
                                                        </svg>
                                                        <span style={styles.textActions}>{language === 'english' ? ('Transfer') : ('Transferir')}</span>
                                                    </button>
                                                    {/* <button style={styles.buttonActions} onClick={handlePopUpFeatures}>
                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-bookmark-plus" viewBox="0 0 16 16">
                                                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                                            <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4z" />
                                                        </svg>
                                                        <span style={styles.textActions}>{language === 'english' ? ('Save') : ('Guardar')}</span>
                                                    </button> */}
                                                    <button style={styles.buttonActions} onClick={handlePopUpFeatures}>
                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                        </svg>
                                                        <span style={styles.textActions}>{language === 'english' ? ('Alert') : ('Alerta')}</span>
                                                    </button>
                                                    {/* <button style={styles.buttonActions} onClick={(e) => handleClose(messagesSelectedRedux.id)}> */}
                                                    <button style={styles.buttonActions} onClick={(e) => handlePopUpClose()}>
                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                        </svg>
                                                        <span style={styles.textActions}>{language === 'english' ? ('Close') : ('Cerrar')}</span>
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    {messagesSelectedRedux.assignedTo == `all` ? (
                                                        <>
                                                            {messagesSelectedRedux.isBot == true ? (
                                                                <div style={styles.buttonActionsGroup}>
                                                                    <button style={styles.buttonActions} onClick={handlePopUpFeatures}>
                                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-shuffle" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z" />
                                                                            <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
                                                                        </svg>
                                                                        <span style={styles.textActions}>{language === 'english' ? ('Transfer') : ('Transferir')}</span>
                                                                    </button>
                                                                    <button style={styles.buttonActions} onClick={handlePopUpFeatures}>
                                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-bookmark-plus" viewBox="0 0 16 16">
                                                                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                                                            <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4z" />
                                                                        </svg>
                                                                        <span style={styles.textActions}>{language === 'english' ? ('Save') : ('Guardar')}</span>
                                                                    </button>
                                                                    <div style={styles.buttonActions}>
                                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                                        </svg>
                                                                        <span style={styles.textActions}>{language === 'english' ? (`Assigned to Bot`) : (`Asignado a Bot`)}</span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div style={styles.buttonActionsGroup}>
                                                                    {messagesSelectedRedux.channel === 'Twitter-Post' ? (
                                                                        <button style={styles.buttonActions} onClick={() => (window.open(messagesSelectedRedux.twitterPostLink))}>
                                                                            <svg style={styles.iconActions} fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                                                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                                            </svg>
                                                                            <span style={styles.textActions}>{language === 'english' ? ('Open Tweet') : ('Abrir Tweet')}</span>
                                                                        </button>
                                                                    ) : (<></>)}
                                                                    <button style={styles.buttonActions} onClick={handlePopUpAssign}>
                                                                        <svg style={styles.iconActions} fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                                        </svg>
                                                                        <span style={styles.textActions}>{language === 'english' ? ('Assign') : ('Asignar')}</span>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div style={styles.buttonActionsGroup}>
                                                            <button style={styles.buttonActions} onClick={handlePopUpFeatures}>
                                                                <svg style={styles.iconActions} fill="currentColor" class="bi bi-shuffle" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z" />
                                                                    <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
                                                                </svg>
                                                                <span style={styles.textActions}>{language === 'english' ? ('Transfer') : ('Transferir')}</span>
                                                            </button>
                                                            <button style={styles.buttonActions} onClick={handlePopUpFeatures}>
                                                                <svg style={styles.iconActions} fill="currentColor" class="bi bi-bookmark-plus" viewBox="0 0 16 16">
                                                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                                                    <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4z" />
                                                                </svg>
                                                                <span style={styles.textActions}>{language === 'english' ? ('Save') : ('Guardar')}</span>
                                                            </button>
                                                            <div style={styles.buttonActions}>
                                                                <svg style={styles.iconActions} fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                                </svg>
                                                                <span style={styles.textActions}>{language === 'english' ? (`Assigned to ${messagesSelectedRedux.assignedTo}`) : (`Asignado a ${messagesSelectedRedux.assignedTo}`)}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>)}
                                        </div>
                                        <div style={styles.singleMessages} ref={messageEl}>
                                            {messagesSelectedRedux.messages ? (<>
                                                {messagesSelectedRedux.messages.map((singleMessage) => {
                                                    if (singleMessage.rol !== "client") {
                                                        return (
                                                            <>
                                                                <div style={styles.chatSent}>{singleMessage.text}</div>
                                                                <div style={styles.hourOfChatSent}>{singleMessage.hour}</div>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                <div style={styles.chatReceived}>{singleMessage.text}</div>
                                                                <div style={styles.hourOfChatReceived}>{singleMessage.hour}</div>
                                                            </>
                                                        );
                                                    }
                                                })}
                                            </>) : (<></>)}
                                        </div>
                                    </div>

                                ) : (
                                    <div style={styles.chatNoMessagesAll}>
                                        <img
                                            src="https://i.postimg.cc/SKKBxj69/enamorado.png"
                                            height="150"
                                            alt="Eric from Inside One"
                                        />
                                        <div style={styles.notReady}>{language === 'english' ? ('Good work!') : ('Buen trabajo!')}</div>
                                    </div>
                                )}

                                <form style={styles.textBoxChat} onSubmit={handleSend}>
                                    <textarea
                                        name="textChat"
                                        //  type="text"
                                        rows="1"
                                        className="form-control"
                                        placeholder={language === 'english' ? ('Write here...') : ('Escribe aquí...')}
                                        style={styles.inputChat}
                                        value={messageTextbox}
                                        onChange={e => setMessageTextbox(e.target.value)}
                                        disabled={messagesSelectedRedux && messagesSelectedRedux.assignedTo != `${loginUser.firstName} ${loginUser.lastName}`}
                                    />
                                    <button type="submit" style={styles.button} onClick={handleSend} disabled={messagesSelectedRedux && messagesSelectedRedux.assignedTo != `${loginUser.firstName} ${loginUser.lastName}`}>{language === 'english' ? ('Send') : ('Enviar')}</button>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <>
                            {status === 'break' ? (
                                <div style={styles.containerNoReady}>
                                    <div style={styles.counter}><div style={styles.textCounter}><span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span></div></div>
                                    <div style={styles.notReady}>{language === 'english' ? ('Ready to resume now?') : ('¿Estás disponible para retomar?')}</div>
                                    <button type="submit" style={styles.formButton} onClick={(e) => handleReady('ready')}>{language === 'english' ? ('Ready now') : ('Volver a estar disponible')}</button>
                                </div>
                            ) : (<>
                                {status === 'lunch' ? (
                                    <div style={styles.containerNoReady}>
                                        <div style={styles.counter}><div style={styles.textCounter}><span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span></div></div>
                                        <div style={styles.notReady}>{language === 'english' ? ('Ready to resume now?') : ('¿Estás disponible para retomar?')}</div>
                                        <button type="submit" style={styles.formButton} onClick={(e) => handleReady('ready')}>{language === 'english' ? ('Ready now') : ('Volver a estar disponible')}</button>
                                    </div>
                                ) : (<>
                                    {status === 'coach' ? (
                                        <div style={styles.containerNoReady}>
                                            <div style={styles.counter}><div style={styles.textCounter}><span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span></div></div>
                                            <div style={styles.notReady}>{language === 'english' ? ('Ready to resume now?') : ('¿Estás disponible para retomar?')}</div>
                                            <button type="submit" style={styles.formButton} onClick={(e) => handleReady('ready')}>{language === 'english' ? ('Ready now') : ('Volver a estar disponible')}</button>
                                        </div>
                                    ) : (
                                        <div style={styles.containerNoReady}>
                                            <img
                                                src="https://i.postimg.cc/XYJ7ZYR2/eric-feliz.png"
                                                height="150"
                                                alt="Eric from Inside One"
                                            />
                                            <div style={styles.notReady}>{language === 'english' ? ('Ready to start now?') : ('¿Estás disponible para comenzar?')}</div>
                                            <button type="submit" style={styles.formButton} onClick={(e) => handleReady('ready')}>{language === 'english' ? ('Ready now') : ('Estoy disponible')}</button>
                                        </div>
                                    )}
                                </>)}
                            </>)}
                        </>)}
                </div>
                {screenSize > 800 && !darkModeState && !chartState && (status === 'break' || status === 'lunch' || status === 'coach') ? (
                    <img
                        style={styles.buttonFloat}
                        src="https://i.postimg.cc/NfXWmWzq/Eric-Saludo-Alpha-baja.gif"
                        alt="Eric from Inside One"
                    />
                ) : (<></>)}
            </div>
        </>
    );
};
