import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import MessageOutGeneral from "../components/MessageOut";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const MessageOutGeneralClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [messages, setMessages] = useState([]);
    // const [campaignsActive, setCampaignsActive] = useState(0);
    // const [campaignsInactive, setCampaignsInactive] = useState(0);

    const dispatch = useDispatch()

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    useEffect(() => {
        window.scrollTo(0, 0);
        // window.addEventListener("resize", handleResize);
        if (loginUser.company) {
            let companyId = loginUser.company
            db.collection('companies').doc(companyId).collection('outbound-messages-history').orderBy('timestamp', "desc")
                .onSnapshot((messagesDocs) => {
                    let messagesToState = []
                    // let campaignsActiveFn = 0
                    // let campaignsInactiveFn = 0
                    messagesDocs.forEach((singleMessage) => {
                        messagesToState.push({
                            id: singleMessage.id,
                            agent: singleMessage.data().agent,
                            channel: singleMessage.data().channel,
                            campaignReturn: singleMessage.data().campaignReturn,
                            clientName: singleMessage.data().clientName,
                            destination: singleMessage.data().destination,
                            documentNumber: singleMessage.data().documentNumber,
                            message: singleMessage.data().message,
                            template: singleMessage.data().template,
                            dateFinal: singleMessage.data().dateFinal,
                            timestamp: singleMessage.data().timestamp
                        })
                        // if (singleCampaign.data().status == 'Active') {
                        //     campaignsActiveFn = campaignsActiveFn + 1
                        // } else {
                        //     campaignsInactiveFn = campaignsInactiveFn + 1
                        // }
                    })
                    setMessages(messagesToState)
                    // setCampaignsActive(campaignsActiveFn)
                    // setCampaignsInactive(campaignsInactiveFn)
                })
        }
    }, []);

    return (
        <div>
            <Sidebar />
            <MessageOutGeneral
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                messages={messages}
            // campaignsActive={campaignsActive}
            // campaignsInactive={campaignsInactive}
            />
        </div>
    );
}

export default MessageOutGeneralClient;