
export const saveMessages = (messages) => (dispatch) =>
    //   console.log('como llega al action:', messages)
    dispatch({
        type: "SET_MESSAGES",
        messages: messages,
    });

export const saveMessagesMe = (messages) => (dispatch) =>
    //   console.log('como llega al action:', messages)
    dispatch({
        type: "SET_MESSAGES_ME",
        messages: messages,
    });

export const saveMessagesSelected = (messages) => (dispatch) =>
    dispatch({
        type: "SET_MESSAGES_SELECTED",
        messages: messages,
    });

export const saveChatSelected = (chatId) => (dispatch) =>
    dispatch({
        type: "SET_CHAT_SELECTED",
        chatId: chatId,
    });

export const savePersonSelected = (userId) => (dispatch) =>
    dispatch({
        type: "SET_PERSON_SELECTED",
        userId: userId,
    });

export const saveChannel = (channel) => (dispatch) =>
    dispatch({
        type: "SET_CHANNEL_SELECTED",
        channel: channel,
    });