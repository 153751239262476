import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyDwAPJnB9fKMYqq9YPcc6rvKjTSQIDbibs",
    authDomain: "insideone-newcontact.firebaseapp.com",
    projectId: "insideone-newcontact",
    storageBucket: "insideone-newcontact.appspot.com",
    messagingSenderId: "453759030706",
    appId: "1:453759030706:web:4e3cc4878fbf0cce7280ab"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export default firebase;