import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Message from "../../components/create/MessageOut";
import AuthService from "../../services/auth.service";
import { logoutUser } from "../../store/actions/loginAction";
import { loginUserPreferences } from "../../store/actions/loginAction";
import { saveMessagesSelected, saveChatSelected, savePersonSelected, saveChannel } from "../../store/actions/messageAction";
import Sidebar from "../Sidebar";
import axios from "axios";
import firebase from '../../config/firebaseIO'

const db = firebase.firestore()

const MessageOutClient = () => {

    const history = useHistory();
    const dispatch = useDispatch()

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [message, setMessage] = useState('');
    // const [campaignReturn, setCampaignReturn] = useState('SV - WhatsApp – PYME');
    const [campaignReturn, setCampaignReturn] = useState('');
    // const [templateSelected, setTemplateSelected] = useState('pyme_generico');
    const [templateSelected, setTemplateSelected] = useState('');
    const [clientName, setClientName] = useState('');

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)
    const [loader, setLoader] = useState(false);
    const [popUpNotificationState, setPopUpNotificationState] = useState(false);
    const [popUpMessageChannelOutState, setPopUpMessageChannelOutState] = useState(true);
    const [channelOut, setChannelOut] = useState('Telegram');

    const [templatesWhatsApp, setTemplatesWhatsApp] = useState([]);

    const handlePopUpNotification = (e) => { setPopUpNotificationState(!popUpNotificationState) };

    const handlePopUpChannel = (e) => { setPopUpMessageChannelOutState(!popUpMessageChannelOutState) };
    const handleChannel = (channel) => {
        setChannelOut(channel);
        setPopUpMessageChannelOutState(false)
    };


    const getDataTemplates = async () => {
        if (channelOut == "WhatsApp") {
            const res = await axios({
                url: "https://api.botmaker.com/v2.0/whatsapp/templates",
                method: 'GET',
                headers: {
                    'access-token': 'eyJhbGciOiJIUzUxMiJ9.eyJidXNpbmVzc0lkIjoiSW5zaWRlT25lIiwibmFtZSI6IkFsZXggS3JhdmNodWsiLCJhcGkiOnRydWUsImlkIjoiOURETTdVRFBSRmhsNWVTZXNWYldubTZVd2lzMSIsImV4cCI6MTgwMzM5NjMzNCwianRpIjoiOURETTdVRFBSRmhsNWVTZXNWYldubTZVd2lzMSJ9.XKEyoxQZZLJVArAX2uIAIVADASMXjH5M6jBC72IVY0eIYANUJEfk21Gicx0FzEffZ4Jzyr80Qz5Jock1Gy3s6w',
                    // 'access-token': 'eyJhbGciOiJIUzUxMiJ9.eyJidXNpbmVzc0lkIjoiU2VndXJvc1N1cmEiLCJuYW1lIjoiQWxleCBLcmF2Y2h1ayIsImFwaSI6dHJ1ZSwiaWQiOiI5RERNN1VEUFJGaGw1ZVNlc1ZiV25tNlV3aXMxIiwiZXhwIjoxODEwNzQyODczLCJqdGkiOiI5RERNN1VEUFJGaGw1ZVNlc1ZiV25tNlV3aXMxIn0.4WqZqeK5JAjrHOhkJKJxDdZEjud8VGQn78V0OWybZU2QV19GqOaAmmzOfu04emBDY07aemD0vu_IwOh-BJ6qpg',
                    'Content-Type': 'application/json'
                },
                params: {
                    state: "APPROVED",
                }
            })
            setTemplatesWhatsApp(res.data.items)
            // console.log("Resultados>", res.data.items)
        }
    }


    useEffect(() => {
        getDataTemplates()
    }, [channelOut])



    // useEffect(() => {

    //     let fullName = `${loginUser.firstName} ${loginUser.lastName}`
    //     db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').where("assignedTo", "==", "all")
    //         //.orderBy("hourUnix")
    //         .onSnapshot((chats) => {
    //             let chatsToState = []
    //             //   setSomeAssigned(false)
    //             chats.forEach((singleChat) => {
    //                 let isNewString = ''
    //                 let isCampaign = singleChat.data().campaign ? singleChat.data().campaign : 'all'
    //                 let agentCampaigns = loginUser.campaigns ? loginUser.campaigns : []
    //                 let index = agentCampaigns.findIndex(element => element.id == isCampaign.id);
    //                 if (singleChat.data().isNew == 'yes' || singleChat.data().isNew == 'no') { isNewString = singleChat.data().isNew } else { isNewString = 'yes' }
    //                 if (isCampaign == 'all' || index > -1) {
    //                     chatsToState.push({
    //                         id: singleChat.id,
    //                         hour: singleChat.data().hour,
    //                         idUser: singleChat.data().idUser,
    //                         nameUser: singleChat.data().nameUser,
    //                         messages: singleChat.data().messages,
    //                         channel: singleChat.data().channel,
    //                         isNew: isNewString,
    //                         assignedTo: singleChat.data().assignedTo ? singleChat.data().assignedTo : 'all',
    //                         twitterPostLink: singleChat.data().twitterPostLink ? singleChat.data().twitterPostLink : false,
    //                         profileUser: singleChat.data().profileUser ? singleChat.data().profileUser : false,
    //                         campaign: isCampaign,
    //                     })
    //                 }
    //             })
    //             dispatch(saveMessages(chatsToState.slice()))
    //             setChats(chatsToState)
    //         })
    //     db.collection('companies').doc('X9O1yJZWO0xurTrcE1cX').collection('messages').where("assignedTo", "==", fullName)
    //         //.orderBy("hourUnix")
    //         .onSnapshot((chatsMe) => {
    //             let chatsToStateMe = []
    //             //   setSomeAssigned(false)
    //             chatsMe.forEach((singleChat) => {
    //                 let isNewString = ''
    //                 if (singleChat.data().isNew == 'yes' || singleChat.data().isNew == 'no') { isNewString = singleChat.data().isNew } else { isNewString = 'yes' }
    //                 chatsToStateMe.push({
    //                     id: singleChat.id,
    //                     hour: singleChat.data().hour,
    //                     idUser: singleChat.data().idUser,
    //                     nameUser: singleChat.data().nameUser,
    //                     messages: singleChat.data().messages,
    //                     channel: singleChat.data().channel,
    //                     isNew: isNewString,
    //                     assignedTo: singleChat.data().assignedTo ? singleChat.data().assignedTo : 'all',
    //                     twitterPostLink: singleChat.data().twitterPostLink ? singleChat.data().twitterPostLink : false,
    //                     profileUser: singleChat.data().profileUser ? singleChat.data().profileUser : false,
    //                     campaign: singleChat.data().campaign ? singleChat.data().campaign : 'all',
    //                 })
    //             })
    //             dispatch(saveMessagesMe(chatsToStateMe.slice()))
    //             setChatsMe(chatsToStateMe)
    //         })
    // }, []);




    const handleChange = (e, state) => {
        if (state === 'name') {
            setName(e.target.value);
        } else if (state === 'phoneNumber') {
            setPhoneNumber(e.target.value);
        } else if (state === 'message') {
            setMessage(e.target.value);
        } else if (state === 'campaignReturn') {
            setCampaignReturn(e.target.value);
        } else if (state === 'clientName') {
            setClientName(e.target.value);
        } else if (state === 'documentNumber') {
            setDocumentNumber(e.target.value);
        }
        // else if (state === 'type') {
        //     const type = e.target.name;
        //     const option = e.target.value;
        //     setType(type);
        //     setOptionSelected(option)
        // }
    };

    const handleSubmit = async (e) => {
        if (e) { e.preventDefault() }

        // setLoader(true)
        if (loginUser.company && phoneNumber != "") {





            //DETERMINA LA HORA
            let unixTimestamp = new Date().getTime()
            let unixTimestampARG = unixTimestamp - 10800000
            let date = new Date(unixTimestamp);
            let hours = date.getHours()
            let hoursString = hours.toString()
            if (hoursString.length === 1) { hoursString = '0' + hoursString }
            let minutes = date.getMinutes()
            let minutesString = minutes.toString()
            if (minutesString.length === 1) { minutesString = '0' + minutesString }
            let seconds = date.getSeconds()
            let secondsString = seconds.toString()
            if (secondsString.length === 1) { secondsString = '0' + secondsString }
            let day = date.getDate()
            let dayString = day.toString()
            if (dayString.length === 1) { dayString = '0' + dayString }
            let month = date.getMonth() + 1
            let monthString = month.toString()
            if (monthString.length === 1) { monthString = '0' + monthString }
            let year = date.getFullYear()
            let dateFinal = hoursString + ":" + minutesString + ":" + secondsString + " " + dayString + "/" + monthString + "/" + year
            let hourFinal = hoursString + ":" + minutesString




            // console.log('company:', loginUser.company)
            // console.log('phoneNumber:', phoneNumber)
            // console.log('message:', message)
            // console.log('campaignReturn:', campaignReturn)
            // console.log('agent:', `${loginUser.firstName} ${loginUser.lastName}`)
            // console.log('clientName:', clientName)
            // console.log('template:', templateSelected)
            // console.log('documentNumber:', documentNumber)
            // console.log('channel:', channelOut)


            //  GUARDA INTERACCION EN DB
            db.collection('companies').doc(loginUser.company).collection('outbound-messages-history').add({
                channel: channelOut,
                destination: phoneNumber,
                message: message,
                template: templateSelected,
                campaignReturn: campaignReturn,
                agent: `${loginUser.firstName} ${loginUser.lastName}`,
                clientName: clientName,
                documentNumber: documentNumber,
                timestamp: unixTimestamp,
                dateFinal: dateFinal
            })



            // const response = await axios({
            //     url: 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_INFOBIP_OUTBOUND',
            //     method: 'POST',
            //     params: {
            //         phoneNumber: phoneNumber,
            //         message: message,
            //         campaignReturn: campaignReturn,
            //         agent: `${loginUser.firstName} ${loginUser.lastName}`
            //     }
            // })

            // axios({
            //     url: 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_INFOBIP_OUTBOUND',
            //     method: 'POST',
            //     params: {
            //         phoneNumber: phoneNumber,
            //         message: message,
            //         campaignReturn: campaignReturn,
            //         agent: `${loginUser.firstName} ${loginUser.lastName}`
            //     }
            // })

            axios({
                url: 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_OUTBOUND',
                method: 'POST',
                params: {
                    company: loginUser.company,
                    phoneNumber: phoneNumber,
                    message: message,
                    campaignReturn: campaignReturn,
                    agent: `${loginUser.firstName} ${loginUser.lastName}`,
                    clientName: clientName,
                    documentNumber: documentNumber,
                    template: templateSelected,
                    channel: channelOut
                }
            })

            // LIMPIA CAMPOS
            setPhoneNumber("");
            setMessage("");
            setCampaignReturn("")
            setClientName("")
            setTemplateSelected("")
            setDocumentNumber("");
            // MENSAJE DE CONFIRMACION
            setPopUpNotificationState(true)
        }
    };

    const handleLogout = () => {
        AuthService.logout()
        dispatch(logoutUser())
        dispatch(loginUserPreferences('status', 'pending'))
        dispatch(saveChatSelected(''))
        dispatch(savePersonSelected(''))
        dispatch(saveChannel(''))
        dispatch(saveMessagesSelected([]))
        history.push("/login");
    };

    return (
        <div>
            <Sidebar />
            <Message
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                name={name}
                phoneNumber={phoneNumber}
                message={message}
                campaignReturn={campaignReturn}
                loader={loader}
                handleLogout={handleLogout}
                popUpNotificationState={popUpNotificationState}
                handlePopUpNotification={handlePopUpNotification}
                templateSelected={templateSelected}
                setTemplateSelected={setTemplateSelected}
                clientName={clientName}
                documentNumber={documentNumber}
                popUpMessageChannelOutState={popUpMessageChannelOutState}
                handlePopUpChannel={handlePopUpChannel}
                handleChannel={handleChannel}
                channelOut={channelOut}
                loginUser={loginUser}
                templatesWhatsApp={templatesWhatsApp}

            />
        </div>
    );
}

export default MessageOutClient;