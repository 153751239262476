import React, { useState, useEffect } from "react";
import Analytics from "../components/Analytics";
import Sidebar from "./Sidebar";
//import firebase from '../config/firebaseIO'
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";

//const db = firebase.firestore()

const AnalyticsClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const dispatch = useDispatch()

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)
    const ready = useSelector(state => state.user.ready)

    const handleResize = (e) => { setScreenSize(window.innerWidth) };

    const handleDarkMode = () => {
        if (darkTheme === false) {
            dispatch(loginUserPreferences('darkTheme', true))
        } else {
            dispatch(loginUserPreferences('darkTheme', false))
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <Sidebar />
            <Analytics
                handleDarkMode={handleDarkMode}
                darkModeState={darkTheme}
                screenSize={screenSize}
                loginUser={loginUser}
                ready={ready}
                language={language}
            />
        </>
    );
}

export default AnalyticsClient;