import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default ({ screenSize, darkModeState, handlePopUpAssign, handleAssign, language }) => {
    const styles = {
        shadowContainer: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
            opacity: '0.5',
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 900,
        },
        chartContainer: {
            display: "flex",
            flexDirection: 'column',
            //    width: `${screenSize > 800 ? ("70%") : ("90%")}`,
            //     height: `${screenSize > 800 ? ("70%") : ("90%")}`,
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ('hsl(0, 0%, 100%)')}`,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '18px',
            zIndex: 900,
        },
        chartTop: {
            display: "flex",
            width: "100%",
            height: "100%",
            flex: 12,
            flexDirection: 'column',
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ('hsl(0, 0%, 100%)')}`,
            alignItems: "center",
            padding: `${screenSize > 800 ? ("20px") : ("30px 30px 20px 30px")}`,
            borderRadius: '18px',
        },
        chartClose: {
            display: "flex",
            alignSelf: "flex-end",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            border: 'none',
            backgroundColor: `transparent`,
            textDecoration: "none",
            outline: 'none',
            padding: '6px'
        },
        popUpTitle: {
            display: "flex",
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            fontFamily: "InterBold"
        },
        popUpSubtitle: {
            display: "flex",
            fontSize: "18px",
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            margin: "20px 60px 30px",
        },
        image: {
            height: '100px',
            margin: '20px'
        },
        chartIndicators: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'center',
            //     margin: "30px",
            flexDirection: 'row'
        },
        buttonCancel: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(228, 12%, 44%)")}`,
            color: '#fff',
            border: 'none',
            borderRadius: 25,
            margin: '20px 8px 45px',
            padding: '12px 32px',
            outline: 0,
            width: '136px'
        },
        buttonContinue: {
            //fontFamily: "InterRegular",
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            color: '#fff',
            border: 'none',
            borderRadius: 25,
            margin: '20px 8px 45px',
            padding: '12px 32px',
            outline: 0,
            width: '136px'
        }
    }
    return (
        <>
            <div style={styles.shadowContainer} />
            <div style={styles.container}>
                <div style={styles.chartContainer}>
                    <div style={styles.chartTop}>
                        <button style={styles.chartClose} onClick={handlePopUpAssign}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                        <img style={styles.image} src="https://i.postimg.cc/hj02Nz0d/normal.png" alt="Eric from Inside One" />
                        <div style={styles.popUpTitle}>{language === 'english' ? ('Assign to me?') : ('¿Asignar a mi?')}</div>
                        <div style={styles.popUpSubtitle}>{language === 'english' ? ('You need to confirm the action.') : ('Necesitas confirmar la acción.')}</div>
                        <div style={styles.chartIndicators}>
                            <button style={styles.buttonCancel} onClick={() => handlePopUpAssign()}>{language === 'english' ? ('Cancel') : ('Cancelar')}</button>
                            <button style={styles.buttonContinue} onClick={() => handleAssign()}>{language === 'english' ? ('Continue') : ('Continuar')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
