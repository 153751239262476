import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import AuthService from "./services/auth.service";

import DemoBBVADialogFlow from "./containers/DemoBBVADialogFlow";
import Onboarding from "./containers/Onboarding";
import Login from "./containers/Login";
import Today from "./containers/Today";
import Analytics from "./containers/Analytics";
import Users from "./containers/Users";
import SingleUser from "./containers/SingleUser";
import Campaigns from "./containers/Campaigns";
import MessagesOutGeneral from "./containers/MessagesOut";
import NewCampaigns from "./containers/create/Campaigns";
import NewMessageGalicia from "./containers/create/Message";
import NewMessage from "./containers/create/MessageOut";
import Profile from "./containers/Profile";
import Settings from "./containers/Settings";

const Main = () => {
  const isAuth = useSelector(state => state.user.isAuth)
  const loginUser = useSelector(state => state.user.loginUser)

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      //   setCurrentUser(user);
    }
  }, []);

  const connStatus = () => {
    AuthService.connStatus();
  }

  // OJO que sin los [] es bucle infinito
  useEffect(() => { connStatus(); }, []);



  return (
    <>
      {isAuth && isAuth === true ? (
        <Switch>
          {/* Create Routes */}
          <Route exact path="/new-campaign" component={NewCampaigns} />
          <Route exact path="/onemessage" component={NewMessageGalicia} />
          <Route exact path="/new-message" component={NewMessage} />
          {/* General Routes */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/onboarding" component={Onboarding} />
          <Route exact path="/today" component={Today} />
          <Route exact path="/analytics" component={Analytics} />
          <Route path="/agents/:idAgent" component={SingleUser} />
          <Route exact path="/agents" component={Users} />
          <Route exact path="/campaigns" component={Campaigns} />
          <Route exact path="/messages" component={MessagesOutGeneral} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/bbva-seguros-whatsapp" component={DemoBBVADialogFlow} />
          <Route exact path="/home" component={Today} />
          {loginUser.oneMessage == "false" ? (<Route path="/" component={Today} />) : (<Route path="/" component={NewMessage} />)}
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/bbva-seguros-whatsapp" component={DemoBBVADialogFlow} />
          <Route path="/" component={Login} />
        </Switch>
      )}
    </>
  )
}

export default Main;