import React from "react";
import { Link } from "react-router-dom";

export default ({ screenSize, handleDarkMode, darkModeState, language, ready }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        topBackColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "29%",
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ("hsl(225, 100%, 98%)")}`,
            borderRadius: "0px 0px 25px 25px"
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
        },
        generalIndicators: {
            display: "flex",
            flex: 4,
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('no-wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("0px 0px 30px")}`,
        },
        singleGenIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("20px 10px")}`,
            height: '205px',
        },
        singleGenIndicatorDoble: {
            display: "flex",
            width: `${screenSize > 800 ? ("47%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("20px 10px")}`,
            height: '205px',
        },
        singleGenIndicatorDobleList: {
            display: "flex",
            width: `100%`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: 0,
            height: '100%',
            overflow: `auto`,
        },
        borderCardF: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(203, 89%, 53%)",
            borderRadius: '5px 5px 0px 0px',
        },
        borderCardT: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(195, 100%, 50%)",
            borderRadius: '5px 5px 0px 0px',
        },
        borderCardI: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundImage: "linear-gradient(to right, hsl(37, 97%, 70%), hsl(329, 70%, 58%))",
            borderRadius: '5px 5px 0px 0px',
        },
        borderCardY: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(348, 97%, 39%)",
            borderRadius: '5px 5px 0px 0px',
        },
        contentSingleIndicator: {
            display: "flex",
            height: '100%',
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            padding: '30px 30px 40px',
        },
        contentSingleNew: {
            display: "flex",
            flexDirection: "row",
            borderBottom: 'thin solid #A8AFD3'
        },
        contentSingleNewFinal: {
            display: "flex",
            alignSelf: 'center',
        },
        userCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            alignItems: 'center',
            justifyContent: 'center',
        },
        textUser: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            marginLeft: '10px'
        },
        newTitle: {
            display: "flex",
            flex: 4,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterBold",
            padding: '10px 10px 10px 20px'
        },
        newDescription: {
            display: "flex",
            flex: 4,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterRegular",
            padding: '10px'
        },
        newDate: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            justifyContent: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterRegular",
            padding: '10px',
            height: '62px'
        },
        newTitleFinal: {
            display: "flex",
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterBold",
            height: '62px'
        },
        numberCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "55px",
            fontFamily: "InterBold",
            marginTop: "-8px",
        },
        detailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
            letterSpacing: '3px',
            marginBottom: '20px'
        },
        compareCardUp: {
            display: "flex",
            color: "hsl(163, 72%, 41%)",
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            alignItems: 'flex-end',
        },
        compareCardDown: {
            display: "flex",
            color: "hsl(356, 69%, 56%)",
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            alignItems: 'flex-end',
        },
        compareCardCenter: {
            display: "flex",
            alignItems: 'center',
            marginBottom: '6px'
        },
        actionText: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        newsIndicators: {
            display: "flex",
            flex: 6,
            flexDirection: "column",
        },
        appsIndicators: {
            display: "flex",
            flex: 6,
            flexDirection: "column",
        },
        titleDetailIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            fontSize: "24px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
            margin: `${screenSize > 800 ? ("20px 20px 0px") : ("0px")}`,
        },
        rowDetailIndicators: {
            display: "flex",
            flex: 10,
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("20px 0px")}`,
        },
        singleDetIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
        },
        singleDetIndicatorTrello: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#1D74B9'
        },
        singleDetIndicatorSlack: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#461447'
        },
        singleDetIndicatorHubspot: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#F27454'
        },
        singleDetIndicatorGuide: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#EB4962'
        },
        singleDetIndicatorZendesk: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#0A333A'
        },
        textDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            //  color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        svgStyle: {
            width: "8px",
            height: '4px',
            alignSelf: 'center',
            marginRight: '5px'
        },
        svgStyleDown: {
            display: "flex",
            width: "8px",
            height: '4px',
            marginRight: '5px'
        },
        svgSocialStyle: {
            width: "20px",
            height: '20px',
            alignSelf: 'center',
        },
        logos: {
            height: '40px',
            margin: '10px 15px',
        },
        icons: {
            width: '20px',
            height: '20px',
            color: '#4dc2f1'
        },
        numberLittleCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "30px",
            fontFamily: "InterBold",
        },
    };

    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (`${month}`.length === 1) { month = "0" + month }
    let date = new Date().getDate()
    if (`${date}`.length === 1) { date = "0" + date }
    let dateTotal = (date + " / " + month + " / " + year)

    const svgUp = <svg xmlns="http://www.w3.org/2000/svg" style={styles.svgStyle} ><path fill="#1EB589" fillRule="evenodd" d="M0 4l4-4 4 4z" /></svg>
    const svgDown = <svg xmlns="http://www.w3.org/2000/svg" style={styles.svgStyle}><path fill="#DC414C" f="evenodd" d="M0 0l4 4 4-4z" /></svg>

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === 'english' ? ('Analytics') : ('Métricas')}</h3>
                        <h3 style={styles.subtitleNavbar}>{dateTotal}</h3>
                    </div>
                    <div className={darkModeState ? ("textDarkToogle") : ("textLightToogle")} style={styles.toogleNavbar}>
                        {darkModeState === true ? (<button style={styles.textToogle} onClick={handleDarkMode}><>{language === 'english' ? ('Light Mode') : ('Tema Claro')}</></button>) : (<button style={styles.textToogle} onClick={handleDarkMode}>{language === 'english' ? ('Dark Mode') : ('Tema Oscuro')}</button>)}
                    </div>
                </div>
                <div style={styles.indicators}>
                    <div style={styles.generalIndicators}>
                        <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator}
                        // to="/recordings"
                        >
                            <div style={styles.borderCardT} />
                            <div style={styles.contentSingleIndicator}>
                                <div style={styles.numberCard}>12</div>
                                <div style={styles.detailCard}>{language === 'english' ? ('IN PROGRESS') : ('EN CURSO')}</div>
                                <div style={styles.compareCardUp}>{svgUp}3 Today</div>
                            </div>
                        </Link>
                        <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator}
                        //to="/recordings"
                        >
                            <div style={styles.borderCardT} />
                            <div style={styles.contentSingleIndicator}>
                                <div style={styles.numberCard}>56</div>
                                <div style={styles.detailCard}>{language === 'english' ? ('FINISHED') : ('FINALIZADAS')}</div>
                                <div style={styles.compareCardDown}>{svgDown}21 Today</div>

                            </div>
                        </Link>
                        <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator}
                        // to="/recordings"
                        >
                            <div style={styles.borderCardT} />
                            <div style={styles.contentSingleIndicator}>
                                <div style={styles.numberCard}>4:23h</div>
                                <div style={styles.detailCard}>{language === 'english' ? ('READY') : ('DISPONIBLE')}</div>
                                <div style={styles.compareCardUp}>{svgUp}01:21 Today</div>
                            </div>
                        </Link>
                        <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator}
                        // to="/recordings"
                        >
                            <div style={styles.borderCardT} />
                            <div style={styles.contentSingleIndicator}>
                                <div style={styles.numberCard}>0:43h</div>
                                <div style={styles.detailCard}>{language === 'english' ? ('BREAK') : ('DESCANSO')}</div>
                                <div style={styles.compareCardDown}>00:11 Today</div>

                            </div>
                        </Link>
                    </div>
                    <div style={styles.newsIndicators}>
                        <h3 style={styles.titleDetailIndicators}>{language === 'english' ? ('Daily') : ('Diario')}</h3>
                        <div style={styles.rowDetailIndicators}>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Average duration') : ('Duración promedio')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>05:22</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Maximum duration') : ('Duración máxima')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>09:12</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Maximum wait') : ('Espera máxima')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-hourglass" viewBox="0 0 16 16">
                                        <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>11:43</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Average rating') : ('Calificación promedio')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
                                        <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                                        <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>95%</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={styles.newsIndicators}>
                        <h3 style={styles.titleDetailIndicators}>{language === 'english' ? ('Monthly') : ('Mensual')}</h3>
                        <div style={styles.rowDetailIndicators}>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Average duration') : ('Duración promedio')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>07:42</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Maximum duration') : ('Duración máxima')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>14:23</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Maximum wait') : ('Espera máxima')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-hourglass" viewBox="0 0 16 16">
                                        <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>12:11</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //to="/users"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Average rating') : ('Calificación promedio')}</div>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
                                        <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                                        <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>83%</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
