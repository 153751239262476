import React from "react";
import { Link } from "react-router-dom";

export default ({ screenSize, darkModeState, language, campaigns, campaignsActive, campaignsInactive }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        campaigns: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        generalIndicators: {
            display: "flex",
            justifyContent: 'space-between',
            flexWrap: `${screenSize > 800 ? ('no-wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("0px 0px 30px")}`,
            width: '100%'
        },
        singleDetIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("49%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            //  margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            margin: `${screenSize > 800 ? ('20px 0px') : ("10px")}`,
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        textDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            //  color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        icons: {
            width: '20px',
            height: '20px',
            color: '#4dc2f1'
        },
        numberLittleCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "30px",
            fontFamily: "InterBold",
        },
        buttonNew: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '12px 32px',
        },
        singleCampaign: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4% 0px -10px') : ("10px")}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        nameCampaign: {
            display: "flex",
            flex: 6,
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: "0px 0px 5px 0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: 'start',
            alignSelf: 'center',
        },
        typeCampaign: {
            display: "flex",
            flex: 3,
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            // textAlign: 'end'
            alignSelf: 'center',
            justifyContent: 'flex-end'
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        }
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === 'english' ? ('Campaigns') : ('Campañas')} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === 'english' ? ('All campaigns settings.') : ('Todas las configuraciones de campañas.')}</h3>
                    </div>
                </div>
                <div style={styles.campaigns}>
                    <div style={styles.generalIndicators}>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}>
                            <div style={styles.contentSingleIndicatorLineTop}>
                                <div style={styles.textDetailCard}>{language === 'english' ? ('Active') : ('Activas')}</div>
                                <svg style={styles.icons} fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                                </svg>
                            </div>
                            <div style={styles.contentSingleIndicatorLineDown}>
                                <div style={styles.numberLittleCard}>{campaignsActive}</div>
                            </div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}>
                            <div style={styles.contentSingleIndicatorLineTop}>
                                <div style={styles.textDetailCard}>{language === 'english' ? ('Inactive') : ('Inactivas')}</div>
                                <svg style={styles.icons} fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z" />
                                </svg>
                            </div>
                            <div style={styles.contentSingleIndicatorLineDown}>
                                <div style={styles.numberLittleCard}>{campaignsInactive}</div>
                            </div>
                        </div>
                    </div>
                    <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.buttonNew} to="/new-campaign">{language === 'english' ? ('New campaign') : ('Nueva campaña')}</Link>
                    {campaigns.length > 0 ? (<>
                        {campaigns.map((singleCampaign) => {
                            return (

                                <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleCampaign} >
                                    <div style={styles.nameCampaign}>{singleCampaign.name}</div>
                                    <div style={styles.typeCampaign}>{singleCampaign.status == 'Active' ? <>{language === 'english' ? ('Active') : ('Activada')}</> : <>{language === 'english' ? ('Inactive') : ('Inactiva')}</>}</div>
                                    {/* <div style={styles.typeCampaign}>{singleCampaign.type == 'Inbound' ? singleCampaign.type : 'Outbound'}</div> */}
                                </button>
                            )
                        })}
                    </>) : (<></>)}
                </div>
            </div>
        </>
    );
};
