import React from "react";

export default ({ screenSize, darkmodeState, screen, loginUser, handleChange }) => {
    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            padding: `${screenSize > 800 ? ("3% 10%") : ("30px 0px")}`,
            height: "100%",
        },
        navbar: {
            display: "flex",
            flex: 2,
            justifyContent: "center",
            alignItems: "center",
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px 30px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        logosGroup: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        icon1: {
            display: "flex",
            height: '55px',
            borderRight: '1px solid lightgrey',
            padding: '10px 50px 10px 0px'
        },
        icon2: {
            display: "flex",
            height: '55px',
            padding: '5px 0px 5px 50px'
        },
        hero: {
            display: "flex",
            flex: 6,
            justifyContent: "center",
            alignItems: "center",
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px 30px 30px")}`,
        },
        imageHero: {
            height: 350,
            padding: 0
        },
        textsHero: {
            display: "flex",
            flex: 6,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column",
        },
        buttonHero: {
            backgroundColor: '#4dc2f1',
            color: '#fff',
            borderRadius: '25px',
            padding: '13px 50px',
            textAlign: 'center',
            fontFamily: 'InterBold',
            margin: '30px 0px',
            border: "0px",
            outline: 0
        },
        intro: {
            display: "flex",
            flex: 10,
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px 0px 40px',
        },
        titleIntro: {
            fontSize: '30px',
            fontFamily: 'InterBold',
            margin: '20px 0px',
            textAlign: 'center',
        },
        textIntro: {
            fontSize: '20px',
            fontFamily: 'InterRegular',
            margin: '20px',
            textAlign: 'center',
            lineHeight: '1.8',
            color: '#637580'
        },
        spanIntro: {
            fontFamily: 'InterBold',
        },
        buttonIntro: {
            backgroundColor: '#4dc2f1',
            color: '#fff',
            borderRadius: '25px',
            padding: '13px 50px',
            textAlign: 'center',
            fontFamily: 'InterBold',
            margin: '50px 0px',
            border: "0px",
            outline: 0
        },
        ericFloat: {
            position: "absolute",
            bottom: 15,
            right: -100,
            display: "flex",
            justifyContent: "flex-end",
            height: '280px'
        },
    };

    return (
        <>
            {screen === 1 ? (
                <div style={styles.container}>
                    <div style={styles.navbar}>
                        <div style={styles.logosGroup}>
                            <img src="https://i.postimg.cc/m26pS9xt/logo-bbva.png" style={styles.icon1} alt="Logo of BBVA" />
                            <img src="https://i.postimg.cc/bN6SJ6fx/logo-insideone.png" style={styles.icon2} alt="Logo of Inside One" />
                        </div>
                    </div>
                    <div style={styles.intro}>
                        <h1 style={styles.titleIntro}>Hola {loginUser.firstName}, Bienvenido a la solución de Islas Digitales!</h1>
                        <h1 style={styles.textIntro}>Queremos ayudarte a <span style={styles.spanIntro}>atender a tus clientes</span> de la forma más eficiente.<br />Para eso pensamos e implementamos <span style={styles.spanIntro}>nuevas soluciones</span> para vos.</h1>
                        <button style={styles.buttonIntro} onClick={handleChange}>Siguiente</button>
                        {screenSize > 800 ? (
                            <img
                                style={styles.ericFloat}
                                src="https://i.postimg.cc/SRjc48Cv/Eric-Apuntando-Alpha-baja.gif"
                                alt="Bot of Inside One"
                            />
                        ) : (<></>)}
                    </div>
                </div>
            ) : (<></>)}
            {screen === 2 ? (
                <div style={styles.container}>
                    <div style={styles.hero}>
                        <img src="https://i.postimg.cc/63FP79Fr/canales.png" style={styles.imageHero} alt="Channels of Inside One" />
                    </div>
                    <div style={styles.textsHero}>
                        <h1 style={styles.titleIntro}>Experiencia multicanal para tus clientes</h1>
                        <h1 style={styles.textIntro}>Utiliza todos los canales digitales para comunicarte con tus clientes.<br />Ofrece una atención personalizada a través de los canales más elegidos.</h1>
                        <button style={styles.buttonHero} onClick={handleChange}>Siguiente</button>
                    </div>
                </div>
            ) : (<></>)}
            {screen === 3 ? (
                <div style={styles.container}>
                    <div style={styles.hero}>
                        <img src="https://i.postimg.cc/ZqfbMDM9/Chat-bot-pana-1.png" style={styles.imageHero} alt="Chatbot of Inside One" />
                    </div>
                    <div style={styles.textsHero}>
                        <h1 style={styles.titleIntro}>Automatiza la atención con agentes virtuales</h1>
                        <h1 style={styles.textIntro}>Utiliza los agentes virtuales personalizados para mejorar la atención.<br />Agiliza la resolución de consultas y trámites de manera efectiva.</h1>
                        <button style={styles.buttonHero} onClick={handleChange}>Comenzar</button>
                    </div>
                </div>
            ) : (<></>)}
        </>
    );
};
