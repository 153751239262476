import React from "react";
import PopUpDelete from "./PopUpDelete";

export default ({ screenSize, darkModeState, language, loginUser, singleAgent, campaignsInput, setCampaignsInput, handleChange, handleAdd, campaignToAdd, popUpClose, handlePopUpClose, handleDelete, setCampaignToDelete }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? ('180px') : (<></>)}`,
        },
        user: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'flex-start',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        singleLine: {
            display: "flex",
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4% 0px -10px') : ("10px")}`,
        },
        singleLineMargin: {
            display: "flex",
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('40px 0px') : ("10px")}`,
        },
        type: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
            alignSelf: 'center',
        },
        value: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
            alignSelf: 'center',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            backgroundColor: `transparent`
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        },
        titleCampaigns: {
            display: "flex",
            alignItems: 'center',
            fontSize: "20px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            margin: `${screenSize > 800 ? ("40px 0px 10px") : ("0px")}`,
        },
        buttonsGroup: {
            display: "flex",
            flexDirection: 'row',
            alignItems: "center",
            justifyContent: 'center',
        },
        buttonNew: {
            display: "flex",
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: "10px 10px 10px 0px",
            padding: '12px 32px',
        },
        buttonCancel: {
            display: "flex",
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "hsl(356, 69%, 56%)",
            border: 'none',
            borderRadius: 5,
            margin: "10px 0px",
            padding: '12px 32px',
        },
        optionsForm: {
            margin: "0px 0px 10px",
            width: "100%",
            padding: '25px 20px',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            border: 'none',
            borderRadius: 4,
            fontFamily: 'InterRegular',
            fontSize: 16,
            textDecoration: "none",
            outline: 'none',
        },
        closeButton: {
            display: "flex",
            alignSelf: "flex-end",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            border: 'none',
            backgroundColor: `transparent`,
            textDecoration: "none",
            outline: 'none',
            padding: '6px'
        },
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{singleAgent.firstName} {singleAgent.lastName}</h3>
                        <h3 style={styles.subtitleNavbar}>{language === 'english' ? ('All configurations of the agent.') : ('Todo las configuraciones del agente.')}</h3>
                    </div>
                </div>
                <div style={styles.user}>
                    {singleAgent ? (<>
                        {popUpClose ? (
                            <PopUpDelete
                                screenSize={screenSize}
                                darkModeState={darkModeState}
                                language={language}
                                handlePopUpClose={handlePopUpClose}
                                handleClose={handleDelete}
                            />
                        ) : (<></>)}
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Name') : ('Nombre')}</div>
                            <div style={styles.value}>{singleAgent.firstName} {singleAgent.lastName}</div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>Rol</div>
                            <div style={styles.value}>{singleAgent.rol}</div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Mail') : ('Correo electrónico')}</div>
                            <div style={styles.value}>{singleAgent.mail}</div>
                        </div>
                        <h3 style={styles.titleCampaigns}>{language === 'english' ? ('Campaigns') : ('Campañas')}</h3>
                        {campaignsInput ? (<>
                            <div style={styles.buttonsGroup}>
                                <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.buttonNew} onClick={() => handleAdd()}>{language === 'english' ? ('Save campaign') : ('Guardar campaña')}</button>
                                <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.buttonCancel} onClick={() => setCampaignsInput(false)}>{language === 'english' ? ('Cancel') : ('Cancelar')}</button>
                            </div>
                            <select className={darkModeState ? ("form-select buttonDark") : ("form-select buttonLight")} aria-label="Asset Type" name="nameOfRol" style={styles.optionsForm} onChange={(e) => handleChange(e)}>
                                <option selected>Select the option</option>
                                {campaignToAdd ? (campaignToAdd.map((singleCampaign) => {
                                    return (<option key={singleCampaign.name} value={singleCampaign.id}>{singleCampaign.name}</option>)
                                })) : (null)}
                            </select>
                        </>) : (
                            <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.buttonNew} onClick={() => setCampaignsInput(true)}>{language === 'english' ? ('Add campaign') : ('Agregar campaña')}</button>
                        )}
                        {singleAgent.campaigns && singleAgent.campaigns.length > 0 ? (
                            <>
                                {singleAgent.campaigns.map((singleCampaign) => {
                                    return (
                                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                                            <div style={styles.type}>{singleCampaign.name}</div>
                                            <button style={styles.closeButton} onClick={() => {
                                                setCampaignToDelete(singleCampaign.id)
                                                handlePopUpClose()
                                            }
                                            }>
                                                <svg width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </button>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                                <div style={styles.type}>{language === 'english' ? ('No assigned campaigns.') : ('Sin campañas asignadas.')}</div>
                            </div>
                        )}
                    </>) : (<></>)}
                </div>
            </div>
        </>
    );
};
