import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import Profile from "../components/Profile";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'
import AuthService from "../services/auth.service";

const db = firebase.firestore()

const SettingsClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [users, setUsers] = useState(window.innerWidth);
    const [popUp, setPopUp] = useState(false);
    const [recoverMail, setRecoverMail] = useState("");
    const [recoverMessage, setRecoverMessage] = useState(false);

    const dispatch = useDispatch()

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    const handlePopUp = (e) => {
        setPopUp(!popUp)
        setRecoverMail("");
        setRecoverMessage(false);
    };

    const handleRecoverMail = (e) => {
        const value = e.target.value;
        setRecoverMail(value);
    };

    const handleRecover = (e) => {
        if (recoverMail != "") {
            AuthService.recover(recoverMail).then(
                (res) => {
                    if (res) {
                        setRecoverMail("");
                        setRecoverMessage("yes");
                    }
                },
                (error) => {
                    setRecoverMessage("no");
                }
            )
        }
    };

    return (
        <div>
            <Sidebar />
            <Profile
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                loginUser={loginUser}
                popUp={popUp}
                handlePopUp={handlePopUp}
                handleRecover={handleRecover}
                handleRecoverMail={handleRecoverMail}
                recoverMessage={recoverMessage}
            />
        </div>
    );
}

export default SettingsClient;