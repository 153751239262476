import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import Settings from "../components/Settings";
import Sidebar from "./Sidebar";

const SettingsClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const dispatch = useDispatch()

    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    const handleDarkMode = () => {
        if (darkTheme === false) {
            dispatch(loginUserPreferences('darkTheme', true))
        } else {
            dispatch(loginUserPreferences('darkTheme', false))
        }
    };

    const handleLanguage = () => {
        if (language === 'english') {
            dispatch(loginUserPreferences('language', 'spanish'))
        } else {
            dispatch(loginUserPreferences('language', 'english'))
        }
    };

    return (
        <div>
            <Sidebar />
            <Settings
                screenSize={screenSize}
                darkModeState={darkTheme}
                handleDarkMode={handleDarkMode}
                language={language}
                handleLanguage={handleLanguage}
            />
        </div>
    );
}

export default SettingsClient;