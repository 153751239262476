import React, { useState } from "react";
import { useSelector } from "react-redux";
import Onboarding from "../components/Onboarding";
import firebase from '../config/firebaseIO'
import { useHistory } from "react-router-dom";

const db = firebase.firestore()

const OnboardingClient = () => {

    const history = useHistory();

    const [screen, setScreen] = useState(1);
    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const loginUser = useSelector(state => state.user.loginUser)

    const handleChange = () => {
        if (screen === 3) {
            setScreen(1)
            let user = loginUser.userUID
            let userToSave = db.collection('users').doc(user)
            userToSave.update({ firstLogin: false })
            history.push("/today");
        } else {
            setScreen(screen + 1)
        }
    }

    return (
        <Onboarding
            screen={screen}
            handleChange={handleChange}
            screenSize={screenSize}
            darkmodeState={darkmode}
            loginUser={loginUser}
        />
    );
}

export default OnboardingClient;