import React from "react";
import { Link } from "react-router-dom";
import { ExportToCsv } from "export-to-csv";
import ReactExport from "react-export-excel";

// Para exportar los archivos en Excel
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default ({ screenSize, darkModeState, language, messages }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        campaigns: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        generalIndicators: {
            display: "flex",
            justifyContent: 'space-between',
            flexWrap: `${screenSize > 800 ? ('no-wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("0px 0px 30px")}`,
            width: '100%'
        },
        singleDetIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("49%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            //  margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            margin: `${screenSize > 800 ? ('20px 0px') : ("10px")}`,
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        textDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            //  color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        icons: {
            width: '20px',
            height: '20px',
            color: '#4dc2f1'
        },
        numberLittleCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "30px",
            fontFamily: "InterBold",
        },
        buttonNew: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '12px 32px',
        },
        singleCampaign: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4% 0px -10px') : ("10px")}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        channelMessage: {
            display: "flex",
            flex: 3,
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: "0px",
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: 'start',
            //  alignSelf: 'center',
            alignItems: 'center',
            //    justifyContent: 'center'
        },
        nameCampaign: {
            display: "flex",
            flex: 3,
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px",
            fontSize: "16px",
            fontFamily: "InterBold",
            textAlign: 'start',
            alignSelf: 'center',
            alignItems: 'center',
        },
        detailChannel: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px",
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: 'start',
            alignSelf: 'center',
            alignItems: 'center',
        },
        agentMessage: {
            display: "flex",
            flex: 3,
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px",
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: 'start',
            alignSelf: 'center',
            alignItems: 'center',
        },
        messsageChannel: {
            display: "flex",
            flex: 6,
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px",
            fontSize: "14px",
            fontFamily: "InterRegular",
            textAlign: 'start',
            alignSelf: 'center',
            alignItems: 'center',
            padding: '0px 60px 0px 0px'
        },
        typeCampaign: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            // textAlign: 'end'
            alignSelf: 'center',
            justifyContent: 'flex-end'
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        },
        imageChannel: {
            height: 40,
            margin: '0px 20px 0px 0px',
        },
        singleCounter: {
            display: "flex",
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "2.4% 0px -10px" : "10px"}`,
            backgroundColor: '#4dc2f1',
            color: '#fff'
        },
        titleCounter: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
            alignSelf: "center",
            color: '#fff'
        },
        numberCounter: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: 'end',
            alignSelf: "center",
            justifyContent: "flex-end",
            color: '#fff'
        },
        downloadsContainer: {
            display: "flex",
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            borderRadius: "5px",
            padding: "10px 0px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "1.4% 0px 0px" : "10px"}`,
            backgroundColor: 'transparent',
        },
        downloadsButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#4dc2f1",
            backgroundColor: "transparent",
            border: '1px #4dc2f1 solid',
            borderRadius: 5,
            margin: "0px 0px 0px 10px",
            padding: '8px 13px',
            width: '180px'
        },
    };


    //array que guardara los datos a exportar y muestra la longitud de los datos mostrados en pantalla
    let csvFile = [];

    //opciones para la descarga de cvs
    let titleCSV = `OneMessage-Report`
    // let titleCSV = `OneMessage-${dateToday}`

    const options = {
        fieldSeparator: ',',
        filename: titleCSV,
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        useBom: true,
        useTextFile: false,
        useKeysAsHeaders: false,
        headers: ["Date", "Contact", "Channel", "Agent", "Message"]
    };

    //funcion para descargar en formato cvs
    let downloadCvs = () => {
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(csvFile);
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === 'english' ? ('Outbound messages') : ('Mensajes salientes')} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === 'english' ? ('All outbound messages information.') : ('Todas la información de mensajes salientes.')}</h3>
                    </div>
                </div>
                <div style={styles.campaigns}>
                    <div style={styles.generalIndicators}>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}>
                            <div style={styles.contentSingleIndicatorLineTop}>
                                <div style={styles.textDetailCard}>{language === 'english' ? ('Sent messages') : ('Mensajes enviados')}</div>
                                {/* <svg style={styles.icons} fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                </svg> */}
                                <svg style={styles.icons} fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                </svg>
                            </div>
                            <div style={styles.contentSingleIndicatorLineDown}>
                                <div style={styles.numberLittleCard}>{messages.length}</div>
                            </div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}>
                            <div style={styles.contentSingleIndicatorLineTop}>
                                <div style={styles.textDetailCard}>{language === 'english' ? ('Contacted numbers') : ('Números contactados')}</div>
                                <svg style={styles.icons} fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>
                            </div>
                            <div style={styles.contentSingleIndicatorLineDown}>
                                <div style={styles.numberLittleCard}>{messages.length}</div>
                            </div>
                        </div>
                    </div>
                    <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.buttonNew} to="/new-message">{language === 'english' ? ('New message') : ('Nuevo mensaje')}</Link>
                    {messages.length > 0 ? (<>
                        {messages.map((singleMessage) => {
                            //los usuarios encontrados se empujan a una variable, asi se muestra en pantalla todos los usuarios encontrados
                            // y facilita su descarga en tanto en excel como en csv
                            csvFile.push({
                                date: singleMessage.dateFinal,
                                contact: singleMessage.destination,
                                channel: singleMessage.channel,
                                agent: singleMessage.agent,
                                message: singleMessage.channel == "WhatsApp" ? (singleMessage.template) : (singleMessage.message)
                            });
                            return (
                                <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleCampaign} >
                                    {singleMessage.channel == "WhatsApp" ? (<div style={styles.channelMessage}><img src="https://i.postimg.cc/nLzTzgVC/whatsapp-logo.png" style={styles.imageChannel} />{singleMessage.channel}</div>) : (<></>)}
                                    {singleMessage.channel == "Telegram" ? (<div style={styles.channelMessage}><img src="https://i.postimg.cc/ydJHFLjN/telegram.png" style={styles.imageChannel} />{singleMessage.channel}</div>) : (<></>)}
                                    {singleMessage.channel == "SMS" ? (<div style={styles.channelMessage}><img src="https://i.postimg.cc/3JHq72NV/sms-logo.png" style={styles.imageChannel} />{singleMessage.channel}</div>) : (<></>)}
                                    {singleMessage.channel == "Email" ? (<div style={styles.channelMessage}><img src="https://i.postimg.cc/nVKk4gVS/email-logo.png" style={styles.imageChannel} />{singleMessage.channel}</div>) : (<></>)}
                                    {/* <div style={styles.nameCampaign}>{singleMessage.channel}</div> */}
                                    <div style={styles.detailChannel}>{singleMessage.destination}</div>
                                    {/* {singleMessage.channel == "WhatsApp" ? (<div style={styles.detailChannel}>{singleMessage.template}</div>) : (<div style={styles.detailChannel}>{`${singleMessage.message.slice(0, 65)}...`}</div>)} */}
                                    {singleMessage.channel == "WhatsApp" ? (<div style={styles.messsageChannel}>{singleMessage.template}</div>) : (<div style={styles.messsageChannel}>{singleMessage.message.length > 30 ? (`${singleMessage.message.slice(0, 85)}...`) : (singleMessage.message)}</div>)}
                                    <div style={styles.agentMessage}>{singleMessage.agent}</div>
                                    <div style={styles.typeCampaign}>{singleMessage.dateFinal}</div>
                                </button>
                            )
                        })}
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleCounter}>
                            <div style={styles.titleCounter}>{language === 'english' ? ('Total messages') : ('Mensajes totales')}</div>
                            <div style={styles.numberCounter}>{csvFile.length}</div>
                        </div>

                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.downloadsContainer}>
                            <ExcelFile element={<button style={styles.downloadsButton}>{language === "english" ? "Download Excel" : "Descargar Excel"}</button>} filename={`OneMessage - Report`}>
                                <ExcelSheet data={csvFile} name={`Messages Report`}>
                                    <ExcelColumn label="Date" value="date" />
                                    <ExcelColumn label="Contact" value="contact" />
                                    <ExcelColumn label="Channel" value="channel" />
                                    <ExcelColumn label="Agent" value="agent" />
                                    <ExcelColumn label="Message" value="message" />
                                </ExcelSheet>
                            </ExcelFile>
                            <button style={styles.downloadsButton} onClick={downloadCvs}>{language === "english" ? "Download CSV" : "Descargar CSV"}</button>
                        </div>
                    </>) : (<></>)}
                </div>
            </div>
        </>
    );
};
