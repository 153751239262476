import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SingleUser from "../components/SingleUser";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const SingleUserClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [user, setUser] = useState({});
    const [campaignToAdd, setCampaignToAdd] = useState([]);
    const [campaignAdded, setCampaignAdded] = useState(false);
    const [campaignToDelete, setCampaignToDelete] = useState('');

    const { idAgent } = useParams();

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    const [campaignsInput, setCampaignsInput] = useState(false);
    const [popUpClose, setPopUpClose] = useState(false);
    const [loader, setLoader] = useState(true);

    const handlePopUpClose = (e) => { setPopUpClose(!popUpClose) };

    useEffect(() => {
        window.scrollTo(0, 0);
        // window.addEventListener("resize", handleResize);
        setLoader(true)
        if (loginUser.company) {
            let singleAgentToState = {}
            db.collection('users').doc(idAgent)
                .get()
                .then((singleAgent) => {
                    singleAgentToState = {
                        id: singleAgent.id,
                        firstLogin: singleAgent.data().firstLogin,
                        firstName: singleAgent.data().firstName,
                        lastName: singleAgent.data().lastName,
                        mail: singleAgent.data().mail,
                        rol: singleAgent.data().rol,
                        campaigns: singleAgent.data().campaigns,
                    }
                }).then(() => {
                    setUser(singleAgentToState); setLoader(false)
                })


            let companyId = loginUser.company
            db.collection('companies').doc(companyId).collection('campaigns').orderBy('name')
                .onSnapshot((campaignsDocs) => {
                    let campaignsToState = []
                    campaignsDocs.forEach((singleCampaign) => {
                        campaignsToState.push({
                            id: singleCampaign.id,
                            name: singleCampaign.data().name,
                        })
                    })
                    setCampaignToAdd(campaignsToState)
                })
        }
    }, []);

    const handleChange = (e) => {
        if (e) { e.preventDefault() }
        if (e.target.value) {
            let idCampaign = e.target.value
            let found = campaignToAdd.find(element => element.id == idCampaign)
            setCampaignAdded(found)
        }
    };

    const handleAdd = (e) => {
        if (e) { e.preventDefault() }
        setCampaignsInput(false)
        if (campaignAdded != "" && campaignAdded != undefined && campaignAdded != false) {
            setLoader(true)
            let campaignsOfUser = user.campaigns ? user.campaigns : []
            campaignsOfUser.push(campaignAdded)
            db.collection('users').doc(idAgent).update({ campaigns: campaignsOfUser })
                .then(() => {
                    setLoader(false)
                })
        }
    };

    const handleDelete = (e) => {
        setPopUpClose(false)
        let campaignsOfUserToDelete = user.campaigns ? user.campaigns : []
        const index = campaignsOfUserToDelete.findIndex(element => element.id == campaignToDelete);
        if (index > -1) {
            campaignsOfUserToDelete.splice(index, 1);
        }
        db.collection('users').doc(idAgent).update({ campaigns: campaignsOfUserToDelete })
            .then(() => {
                setLoader(false)
            })
    };

    return (
        <div>
            <Sidebar />
            <SingleUser
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                singleAgent={user}
                campaignsInput={campaignsInput}
                setCampaignsInput={setCampaignsInput}
                handleChange={handleChange}
                handleAdd={handleAdd}
                campaignToAdd={campaignToAdd}
                handlePopUpClose={handlePopUpClose}
                popUpClose={popUpClose}
                handleDelete={handleDelete}
                setCampaignToDelete={setCampaignToDelete}
            />
        </div>
    );
}

export default SingleUserClient;