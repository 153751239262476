import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import Users from "../components/Users";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const SettingsClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [users, setUsers] = useState(window.innerWidth);

    const dispatch = useDispatch()

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)



    useEffect(() => {
        window.scrollTo(0, 0);
        // window.addEventListener("resize", handleResize);
        if (loginUser.company) {
            let companyId = loginUser.company
            db.collection('users').where("company", "==", companyId)
                .onSnapshot((users) => {
                    let usersToState = []
                    users.forEach((singleUser) => {
                        if (singleUser.data().rol != 'Developer') {
                            usersToState.push({
                                id: singleUser.id,
                                firstLogin: singleUser.data().firstLogin,
                                firstName: singleUser.data().firstName,
                                lastName: singleUser.data().lastName,
                                mail: singleUser.data().mail,
                                rol: singleUser.data().rol,
                            })
                        }
                    })
                    setUsers(usersToState)
                })
        }
    }, []);

    return (
        <div>
            <Sidebar />
            <Users
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                users={users}
            />
        </div>
    );
}

export default SettingsClient;