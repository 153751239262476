import firebase from '../../config/firebaseIO'
//import { useHistory } from "react-router-dom";

const db = firebase.firestore()

export const loginUser = (userUID, historyRoute) => (dispatch) => {
  // const history = useHistory();
  db.collection("users").doc(userUID)
    .get()
    .then((user) => {
      let userCompany = user.data().company
      db.collection("companies").doc(userCompany)
        .get()
        .then((company) => {
          dispatch({
            type: "SET_LOGIN",
            user: {
              userUID: userUID,
              firstName: user.data().firstName,
              lastName: user.data().lastName,
              rol: user.data().rol,
              firstLogin: user.data().firstLogin,
              company: user.data().company,
              mail: user.data().mail,
              campaigns: user.data().campaigns,
              oneMessage: user.data().oneMessage ? user.data().oneMessage : "false",
              oneAgent: user.data().oneAgent ? user.data().oneAgent : "false",
              channelsOutbound: company.data().channelsOutbound ? company.data().channelsOutbound : false,
            },
          });
        })
      return user
    })
    .then((user) => {
      //console.log("channelsOutbound", user.data())

      if (user.data().company == "qdSTVBdbXQdvIWGbfSgI") {
        historyRoute.push("/onemessage");
      } else {


        if (user.data().oneMessage === "true" && user.data().oneAgent === "false") {
          historyRoute.push("/messages");
        } else {
          if (user.data().firstLogin === true) {
            historyRoute.push("/onboarding");
          } else {
            historyRoute.push("/today");
          }
        }



      }


    })
};

export const loginUserPreferences = (typeOfDispatch, valueOfDispatch) => (dispatch) => {
  if (typeOfDispatch === 'darkTheme') {
    dispatch({
      type: "SET_DARK_THEME",
      darkTheme: valueOfDispatch
    });
  } else if (typeOfDispatch === 'language') {
    dispatch({
      type: "SET_LANGUAGE",
      language: valueOfDispatch
    });
  } else if (typeOfDispatch === 'status') {
    dispatch({
      type: "SET_READY",
      status: valueOfDispatch
    });
  }
};

export const logoutUser = () => (dispatch) =>
  dispatch({
    type: "SET_LOGOUT",
    user: {},
  });