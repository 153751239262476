import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default ({ screenSize, darkModeState, handleStatus, handleReady }) => {
    const styles = {
        shadowContainer: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
            opacity: '0.5',
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 900,
        },
        chartContainer: {
            display: "flex",
            flexDirection: 'column',
            //    width: `${screenSize > 800 ? ("70%") : ("90%")}`,
            //     height: `${screenSize > 800 ? ("70%") : ("90%")}`,
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ('hsl(0, 0%, 100%)')}`,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '18px',
            zIndex: 900,
        },
        chartTop: {
            display: "flex",
            width: "100%",
            height: "100%",
            flex: 12,
            flexDirection: 'column',
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ('hsl(0, 0%, 100%)')}`,
            alignItems: "center",
            padding: `${screenSize > 800 ? ("20px") : ("30px 30px 20px 30px")}`,
            borderRadius: '18px',
        },
        chartClose: {
            display: "flex",
            alignSelf: "flex-end",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            border: 'none',
            backgroundColor: `transparent`,
            textDecoration: "none",
            outline: 'none',
            padding: '6px'
        },
        chartTitle: {
            display: "flex",
            margin: "0px 80px",
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            fontFamily: "InterBold"
        },
        chartUser: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ('hsl(228, 18%, 64%)')}`,
            fontFamily: "InterBold",
            fontSize: "14px",
            margin: 0,
        },
        chartIndicators: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: "30px",
            flexDirection: 'column'
        },
        chartDetails: {
            display: 'flex',
            flex: 12,
            margin: "15px",
            fontSize: "18px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            fontFamily: "InterRegular",
            lineHeight: '1.4',
            alignItems: 'center',
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ('hsl(0, 0%, 100%)')}`,
            border: 'none',
            outline: 'none',
            padding: 0
        },
        chartNumbersDetailsLarge: {
            margin: "0px 14px",
            width: '120px',
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            fontFamily: "InterRegular",
            lineHeight: '1.4'
        },
        svgStyle: {
            width: "8px",
            height: '4px',
            alignSelf: 'center',
            margin: '8px'
        },
        chartBottom: {
            display: "flex",
            flex: 8,
            flexDirection: 'column',
            width: "100%",
            height: "100%",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: `${screenSize > 800 ? ("30px 40px 30px 22px") : ("30px 40px 30px 10px")}`,
        },
        dateChart: {
            margin: 0,
            fontSize: "18px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ('hsl(228, 2%, 64%)')}`,
            fontFamily: "InterRegular",
            paddingLeft: `${screenSize > 800 ? ("35px") : ("35px")}`,
        },
        spanEmoji: {
            margin: '0px 35px 0px 0px',
            fontSize: 40
        },
    }
    return (
        <>
            <div style={styles.shadowContainer} />
            <div style={styles.container}>
                <div style={styles.chartContainer}>
                    <div style={styles.chartTop}>
                        <button style={styles.chartClose} onClick={handleStatus}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                        <div style={styles.chartTitle}>Cambia tu estado</div>
                        <div style={styles.chartIndicators}>
                            <button style={styles.chartDetails} onClick={(e) => handleReady('ready')}><span style={styles.spanEmoji}>💻</span> Disponible</button>
                            <button style={styles.chartDetails} onClick={(e) => handleReady('break')}><span style={styles.spanEmoji}>☕️</span> Descanso</button>
                            <button style={styles.chartDetails} onClick={(e) => handleReady('lunch')}><span style={styles.spanEmoji}>🍔</span> Almuerzo</button>
                            <button style={styles.chartDetails} onClick={(e) => handleReady('coach')}><span style={styles.spanEmoji}>🗓</span> Reunión</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
