import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Campaigns from "../../components/create/Campaigns";
import Sidebar from "./../Sidebar";
import firebase from '../../config/firebaseIO'

const db = firebase.firestore()

const CampaignsClient = () => {

    const history = useHistory();

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [name, setName] = useState('');

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)
    const [loader, setLoader] = useState(false);

    const handleChange = (e, state) => {
        if (state === 'name') {
            const nameForm = e.target.value;
            setName(nameForm);
        }
        // else if (state === 'type') {
        //     const type = e.target.name;
        //     const option = e.target.value;
        //     setType(type);
        //     setOptionSelected(option)
        // }
    };

    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        if (loginUser.company && name != "") {
            setLoader(true)
            let companyId = loginUser.company
            db.collection('companies').doc(companyId).collection('campaigns').add({
                name: name, type: "Outbound", status: "Active"
            }).then(() => {
                history.push(`/campaigns`);
            })
        }
    };

    return (
        <div>
            <Sidebar />
            <Campaigns
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                name={name}
                loader={loader}
            />
        </div>
    );
}

export default CampaignsClient;