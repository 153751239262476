import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Message from "../../components/create/Message";
import AuthService from "../../services/auth.service";
import { logoutUser } from "../../store/actions/loginAction";
import { loginUserPreferences } from "../../store/actions/loginAction";
import { saveMessagesSelected, saveChatSelected, savePersonSelected, saveChannel } from "../../store/actions/messageAction";
import Sidebar from "../Sidebar";
import axios from "axios";
import firebase from '../../config/firebaseIO'

const db = firebase.firestore()

const MessageOutClient = () => {

    const history = useHistory();
    const dispatch = useDispatch()

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [message, setMessage] = useState('');
    const [campaignReturn, setCampaignReturn] = useState('SV - WhatsApp – PYME');

    const [templateSelected, setTemplateSelected] = useState('pyme_generico');
    const [clientName, setClientName] = useState('');

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)
    const [loader, setLoader] = useState(false);
    const [popUpNotificationState, setPopUpNotificationState] = useState(false);

    const handlePopUpNotification = (e) => { setPopUpNotificationState(!popUpNotificationState) };

    const handleChange = (e, state) => {
        if (state === 'name') {
            setName(e.target.value);
        } else if (state === 'phoneNumber') {
            setPhoneNumber(e.target.value);
        } else if (state === 'message') {
            setMessage(e.target.value);
        } else if (state === 'campaignReturn') {
            setCampaignReturn(e.target.value);
        } else if (state === 'clientName') {
            setClientName(e.target.value);
        } else if (state === 'documentNumber') {
            setDocumentNumber(e.target.value);
        }
        // else if (state === 'type') {
        //     const type = e.target.name;
        //     const option = e.target.value;
        //     setType(type);
        //     setOptionSelected(option)
        // }
    };

    const handleSubmit = async (e) => {
        if (e) { e.preventDefault() }

        // console.log('loginUser:', loginUser.company)
        // console.log('phoneNumber:', phoneNumber)
        // console.log('campaignReturn:', campaignReturn)

        if (loginUser.company && phoneNumber != "" && campaignReturn != "") {
            //  setLoader(true)
            //  let companyId = loginUser.company
            // ENVIA AXIOS CON LOS DATOS A FUNCTION PARA ENVIO DE MENSAJERIA

            //   db.collection('companies').doc('qdSTVBdbXQdvIWGbfSgI').collection('outbound-messages').add({ probando: "hola2" })


            // const response = await axios({
            //     url: 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_INFOBIP_OUTBOUND',
            //     method: 'POST',
            //     params: {
            //         phoneNumber: phoneNumber,
            //         message: message,
            //         campaignReturn: campaignReturn,
            //         agent: `${loginUser.firstName} ${loginUser.lastName}`
            //     }
            // })

            // axios({
            //     url: 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_INFOBIP_OUTBOUND',
            //     method: 'POST',
            //     params: {
            //         phoneNumber: phoneNumber,
            //         message: message,
            //         campaignReturn: campaignReturn,
            //         agent: `${loginUser.firstName} ${loginUser.lastName}`
            //     }
            // })


            axios({
                url: 'https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_ISLES_TO_INFOBIP_OUTBOUND',
                method: 'POST',
                params: {
                    phoneNumber: phoneNumber,
                    message: message,
                    campaignReturn: campaignReturn,
                    agent: `${loginUser.firstName} ${loginUser.lastName}`,
                    clientName: clientName,
                    template: templateSelected,
                    documentNumber: documentNumber,
                    mail: loginUser.mail
                }
            })


            // LIMPIA CAMPOS
            setPhoneNumber("");
            setDocumentNumber("");
            setMessage("");
            setClientName("")
            setTemplateSelected("pyme_generico")
            // MENSAJE DE CONFIRMACION
            setPopUpNotificationState(true)

        }
    };

    const handleLogout = () => {
        AuthService.logout()
        dispatch(logoutUser())
        dispatch(loginUserPreferences('status', 'pending'))
        dispatch(saveChatSelected(''))
        dispatch(savePersonSelected(''))
        dispatch(saveChannel(''))
        dispatch(saveMessagesSelected([]))
        history.push("/login");
    };

    return (
        <div>
            <Sidebar />
            <Message
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                name={name}
                phoneNumber={phoneNumber}
                message={message}
                campaignReturn={campaignReturn}
                loader={loader}
                handleLogout={handleLogout}
                popUpNotificationState={popUpNotificationState}
                handlePopUpNotification={handlePopUpNotification}
                templateSelected={templateSelected}
                setTemplateSelected={setTemplateSelected}
                clientName={clientName}
                documentNumber={documentNumber}
            />
        </div>
    );
}

export default MessageOutClient;